import Vue from 'vue';
import VueRouter from 'vue-router';
import Dashboard from '../views/Dashboard.vue';
import ViewGatEdit from '../views/ViewGatEdit.vue';
import DebugGatGridItems from '../views/debug/debugGatGridItems.vue';
import ViewGatCodeEdit from '../views/ViewGatCodeEditor.vue';
import ViewGatGridProperties from '../views/ViewGatGridProperties.vue';
import ViewGatSelect from '../views/ViewGatSelect.vue';
import ViewGatMultiSelect from '../views/ViewGatMultiSelect.vue';
import ViewGatGroup from '../views/ViewGatGroup.vue';
import ViewGatGridOverview from '../views/ViewGatGridOverview.vue';
import ViewGatGridColumns from '../views/ViewGatGridColumns.vue';
import ViewGatGridEvents from '../views/ViewGatGridEvents.vue';
import ViewGatGridFavorites from '../views/ViewGatGridFavorites.vue';
import ViewGatGridMenu from '../views/ViewGatGridMenu.vue';
import ViewGatGridSelection from '../views/ViewGatGridSelection.vue';
import ViewGatGridSlots from '../views/ViewGatGridSlots.vue';
import ViewGatGridView from '../views/ViewGatGridView.vue';
import ViewGatButton from '../views/ViewGatButton.vue';
import ViewGatBtnView from '../views/ViewGatButtonGroup.vue';
import ViewGatDialog from '../views/ViewGatDialog.vue';
import TestPage from '../views/debug/testPage.vue';
import Test2 from '../views/debug/Test2.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
  },
  {
    path: '/test',
    name: 'Test page',
    component: TestPage,
  },
  {
    path: '/gat-button',
    name: 'GatButton',
    component: ViewGatButton,
  },
  {
    path: '/gat-button-group',
    name: 'GatBtnGroup',
    component: ViewGatBtnView,
  },
  {
    path: '/gat-edit',
    name: 'GatEdit',
    component: ViewGatEdit,
  },
  {
    path: '/gatcodeedit',
    name: 'GatCodeEdit',
    component: ViewGatCodeEdit,
  },
  {
    path: '/gat-dialog',
    name: 'GatDialog',
    component: ViewGatDialog,
  },

  {
    path: '/gat-grid-columns',
    name: 'GatGridColumns',
    component: ViewGatGridColumns,
  },
  {
    path: '/gat-grid-properties',
    name: 'GatGridProperties',
    component: ViewGatGridProperties,
  },
  {
    path: '/gat-grid-favorites',
    name: 'GatGridFavorites',
    component: ViewGatGridFavorites,
  },
  {
    path: '/gat-grid-menu',
    name: 'GatGridMenu',
    component: ViewGatGridMenu,
  },
  {
    path: '/gat-grid-selections',
    name: 'GatGridSelection',
    component: ViewGatGridSelection,
  },
  {
    path: '/gat-grid-slots',
    name: 'GatGridSlots',
    component: ViewGatGridSlots,
  },
  {
    path: '/gat-grid-overview',
    name: 'GatGridOverview',
    component: ViewGatGridOverview,
  },
  {
    path: '/gat-grid-events',
    name: 'GatGridEvents',
    component: ViewGatGridEvents,
  },
  {
    path: '/gat-grid-view',
    name: 'GatGridView',
    component: ViewGatGridView,
  },
  {
    path: '/gat-select-edit',
    name: 'GatSelectEdit',
    component: ViewGatSelect,
  },
  {
    path: '/gat-multi-select-edit',
    name: 'GatMultiSelectEdit',
    component: ViewGatMultiSelect,
  },
  {
    path: '/gat-group-page',
    name: 'GatGroupPage',
    component: ViewGatGroup,
  },
  {
    path: '/debug/gatgriditems',
    name: 'DebugGatGridItems',
    component: DebugGatGridItems,
  },
  {
    path: '/test2',
    name: 'test2',
    component: Test2,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
