<template>
  <v-navigation-drawer v-model="drawer" :clipped="$vuetify.breakpoint.lgAndUp" app>
    <v-list dense class="sgrey lighten-4" nav>
      <drawer-menu-item v-for="(item, index) in menuItems" :key="index" :value="item" />
    </v-list>

    <div class="docked-bottom ml-1" v-show="$vuetify.breakpoint.lgAndUp">
      <div class="poweredBy caption pl-1">{{ version }}</div>
    </div>
  </v-navigation-drawer>
</template>

<script>
import DrawerMenuItem from './DrawerMenuItem.vue';

export default {
  name: 'MainMenu',
  props: ['tab', 'showDrawer'],
  components: { DrawerMenuItem },
  data() {
    return {
      drawer: null,

      // items: [
      // { icon: "mdi-view-dashboard", text: "Dashboard", to:"/" },
      // { icon: "mdi-account-multiple ", text: "Customers", to:"/customers"
      //     ,children:[   { icon: "mdi-account-multiple ", text: "Invoicing", to:"/invoices"}]
      //  },
      // { icon: "mdi-database", text: "Datamodel", to:"/datamodel/schema" },
      // { icon: "mdi-bug", text: "App state", to:"/app-state" }
      // ]
    };
  },

  created() {},

  watch: {
    showDrawer: {
      handler(val) {
        this.drawer = val;
      },
      immediate: true,
    },
    drawer(val) {
      this.$emit('drawerChanged', val);
    },
  },

  computed: {
    menuItems() {
      const m = [
        {
          icon: 'mdi-select-group',
          text: 'gat-buttons',
          url: '/gat-button-group',
          model: false,
          children: [
            { text: 'gat-button', url: '/gat-button' },
            { text: 'gat-button-group', url: '/gat-button-group' },
          ],
        },
        { icon: 'mdi-form-textbox', text: 'gat-code-edit', url: '/gatcodeedit' },
        { icon: 'mdi-open-in-app', text: 'gat-dialog', url: '/gat-dialog' },
        { icon: 'mdi-form-textbox', text: 'gat-edit', url: '/gat-edit' },
        {
          icon: 'mdi-table-large',
          text: 'gat-grid',
          url: '/gat-grid-overview',
          model: false,
          children: [
            { text: 'overview', url: '/gat-grid-overview' },
            { text: 'column setup', url: '/gat-grid-columns' },
            { text: 'events', url: '/gat-grid-events' },
            { text: 'favorites', url: '/gat-grid-favorites' },
            { text: 'menu', url: '/gat-grid-menu' },
            { text: 'properties', url: '/gat-grid-properties' },
            { text: 'selections', url: '/gat-grid-selections' },
            { text: 'slots', url: '/gat-grid-slots' },
            { text: 'view', url: '/gat-grid-view' },
          ],
        },
        { icon: 'mdi-group', text: 'gat-group', url: '/gat-group-page' },
        { icon: 'mdi-form-select', text: 'gat-select', url: '/gat-select-edit' },
        { icon: 'mdi-form-select', text: 'gat-multi-select', url: '/gat-multi-select-edit' },
      ];

      return m;
    },

    version() {
      return ' ';
    },
  },

  methods: {},
};
</script>

<style scoped>
.docked-bottom {
  position: absolute;
  bottom: 0px;
}
</style>
