import GatCodeEditor from '@/gat-components/gatInput/GatCodeEdit.vue';
import PropertyList from './PropertyList.vue';
import EventList from './EventList.vue';

import EventLogger from './EventLogger.vue';

export const LocalComponentMixin = {
  components: { PropertyList, GatCodeEditor, EventList, EventLogger },

  methods: {},
};
