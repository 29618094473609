<template>
  <gat-button-menu
    v-if="isMenuButton"
    :caption="isPhone && hideCaptionOnPhone ? '' : caption"
    :disabled="disabled || !userHasAccess"
    :icon="icon"
    :menuItems="menuItems"
    :mainAction="mainAction"
    :open-on-hover="openMenuOnHover"
    @menuItemClick="menuItemClicked"
    @subMenuItemClick="subMenuItemClicked" />
  <v-btn
    v-else
    class="ml-1"
    color="primary"
    :disabled="disabled || !userHasAccess"
    :outlined="!mainAction"
    small
    @click="btnClicked">
    <v-icon small :class="isPhone && hideCaptionOnPhone ? '' : 'mr-1'">{{ icon }}</v-icon>
    <span v-if="!(isPhone && hideCaptionOnPhone)">{{ caption }}</span>
  </v-btn>
</template>

<script>
import GatButtonMenu from './GatButtonMenu.vue';

export default {
  name: 'GatButton',
  props: {
    caption: String,
    disabled: Boolean,
    embeddMenuItems: {
      type: Boolean,
      default: true,
    },
    icon: String,
    mainAction: Boolean,
    menuItems: Array,
    hideCaptionOnPhone: Boolean,
    openMenuOnHover: Boolean,
    userRight: String,
  },
  components: { GatButtonMenu },
  data() {
    return {};
  },

  created() {},

  watch: {},

  computed: {
    isMenuButton() {
      return this.menuItems && this.menuItems.length > 0;
    },

    isPhone() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    userHasAccess() {
      if (this.$store && this.$store.state.user && this.$store.state.user.userRights) {
        return this.$store.getters.userRight(this.userRight);
      }
      return true;
    },
  },

  methods: {
    btnClicked() {
      if (!this.isMenuButton) {
        this.$emit('click');
      }
    },

    menuItemClicked(item) {
      this.$emit('menuItemClick', item);
    },

    subMenuItemClicked(params) {
      // console.log(params);
      this.$emit('menuItemClick', params.item);
    },
  },
};
</script>

<style scoped></style>
