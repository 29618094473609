<template>
  <div class="pa-5">
    <h1 class="v-heading text-h3 text-sm-h3 mb-4">gat-grid favorites</h1>

    <v-layout wrap>
      <gat-grid
        size="xs6"
        title="gat-grid"
        :items="items"
        :columns="columns"
        keyName="ID"
        :favorites="favorites"
        @favClick="addOrRemoveFavorite" />
    </v-layout>
    <br />
    <p>
      To activate the favorites column, an array with favorite objects must be assigned to the
      <code>favorites</code> property.
    </p>
    <gat-code-editor
      value='<gat-grid :items="items" :columns="columns"  keyName="ID" :favorites="favorites" @favClick="addOrRemoveFavorite"/>'
      language="vue" />
    <br />

    <p>
      The favorite object should contain an property <code>FAV_KEY</code> where the value is equal to the key value of
      the row
    </p>
    <gat-code-editor :value="JSON.stringify(favorites)" language="js" />
    <br />
    <p>
      An eventhandler must be triggered on the <code>favClick</code> event, and here the adding and removal of favorites
      must be handled
    </p>
    <gat-code-editor
      value=" addOrRemoveFavorite(fav_obj){
                let idx = this.favorites.findIndex(x=>{return x.FAV_KEY == fav_obj.ID} );
                if(idx>=0){
                    this.favorites.splice(idx,1);
                } else{
                    this.favorites.push({'FAV_KEY':fav_obj.ID});
                }
            }"
      language="js" />
  </div>
</template>

<script>
export default {
  name: 'ViewGatGridFavorites',
  props: [],
  components: {},
  data() {
    return {
      favorites: [{ FAV_KEY: 10 }, { FAV_KEY: 40 }],
    };
  },

  created() {},

  watch: {},

  computed: {
    columns() {
      const result = [
        { header: 'Name', field: 'NAME' },
        { header: 'Country', field: 'COUNTRY' },
        { header: 'EU country', field: 'EU', checkbox: true, hideOnPhone: false },
      ];
      return result;
    },
    items() {
      return [
        { ID: 10, NAME: 'GAT-Ship AS', EU: false, COUNTRY: 'Norway', LAST_CALL: '2020/2938' },
        { ID: 20, NAME: 'Wilmur Shipping', EU: true, COUNTRY: 'Denmark', LAST_CALL: '2020/1392' },
        { ID: 40, NAME: 'Shell inc', EU: false, COUNTRY: 'USA', LAST_CALL: '2019/0320' },
      ];
    },
  },

  methods: {
    addOrRemoveFavorite(fav_obj) {
      const idx = this.favorites.findIndex((x) => x.FAV_KEY == fav_obj.ID);
      if (idx >= 0) {
        this.favorites.splice(idx, 1);
      } else {
        this.favorites.push({ FAV_KEY: fav_obj.ID });
      }
    },
  },
};
</script>

<style scoped></style>
