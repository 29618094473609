<template>
  <div>
    <v-list-item v-if="!value.children" :to="getRoute(value)">
      <!-- icon -->
      <v-list-item-icon>
        <v-icon>{{ value.icon }}</v-icon>
      </v-list-item-icon>
      <!-- label -->
      <v-list-item-title>{{ value.text }}</v-list-item-title>
    </v-list-item>

    <!-- menu item with sub menu -->
    <v-list-group v-else eager>
      <v-divider></v-divider>
      <template v-slot:activator>
        <!-- icon -->
        <!-- <v-icon slot="prependIcon">{{value.icon}}</v-icon> -->
        <v-list-item-icon>
          <v-icon>{{ value.icon }}</v-icon>
        </v-list-item-icon>
        <!-- label -->
        <v-list-item-title>{{ value.text }}</v-list-item-title>
      </template>
      <drawer-menu-item
        v-for="(item, index) in value.children"
        :key="index"
        v-show="!item.userRight || $store.getters.userRight(item.userRight)"
        :value="item"
        :subItem="true" />
      <v-divider></v-divider>
    </v-list-group>
  </div>
</template>

<script>
export default {
  name: 'DrawerMenuItem',
  props: ['value', 'subItem'],
  components: {},
  data() {
    return {};
  },

  created() {},

  watch: {},

  methods: {
    getRoute(menuItem) {
      const result = {};
      if (menuItem.routeName) {
        result.name = menuItem.routeName;
      } else if (menuItem.url) {
        result.path = menuItem.url;
      } else {
        result.name = 'NotImplemented';
      }
      return result;
    },
  },
};
</script>

<style scoped></style>
