import moment from 'moment';

export const GlobalDateUtilsMixin = {
  data: () => ({
    defaultGlobalDateFormat: 'DD.MM.YYYY',
    defaultGlobalDateTimeFormat: 'DD.MM.YYYY HH:mm',
    defaultGlobalShortDateFormat: 'DD MMM',
    defaultGlobalTimeFormat: 'HH:mm:ss',
    defaultGlobalShortTimeFormat: 'HH:mm',
  }),
  methods: {
    // format date based on a given format
    // eslint-disable-next-line consistent-return
    globalFormatDate(date, format) {
      if (!format) {
        // eslint-disable-next-line no-param-reassign
        format = this.$store.state.settings.dateFormat
          ? this.$store.state.settings.dateFormat
          : this.defaultGlobalDateFormat;
      }
      const m = moment(date);
      if (m.isValid()) {
        return m.format(format);
      }
    },

    // eslint-disable-next-line consistent-return
    globalFormatDateTime(date, format) {
      if (!format) {
        // eslint-disable-next-line no-param-reassign
        format = this.$store.state.settings.dateFormat
          ? this.$store.state.settings.dateFormat
          : this.defaultGlobalDateTimeFormat;
      }
      const m = moment(date);
      if (m.isValid()) {
        return m.format(format);
      }
    },

    // eslint-disable-next-line consistent-return
    globalFormatTime(time, format) {
      if (!format) {
        // eslint-disable-next-line no-param-reassign
        format = this.defaultGlobalShortTimeFormat;
      }
      const mtime = moment(time, this.defaultGlobalTimeFormat);
      if (mtime.isValid()) {
        return mtime.format(format);
      }
    },

    globalGetLastDateOfPreviousMonth() {
      // get day of month
      const dayOfMonth = moment().date();

      // get date of last day prev month
      const lastDayOfPreviousMonth = moment().add(-1 * dayOfMonth, 'days');

      // Set the day to the last day of previous month, no time part
      return lastDayOfPreviousMonth.format('YYYY-MM-DD');
    },

    globalAddDaysToDate(date, days) {
      const result = moment(date).add(days, 'days');
      return result.format('YYYY-MM-DD');
    },

    // eslint-disable-next-line consistent-return
    formatDuration(durationInMs, durationFormat = 'daysHourMinutes') {
      const TotalInseconds = durationInMs / 1000;
      const seconds = Math.floor(TotalInseconds % 60);
      let minutes = Math.floor(TotalInseconds / 60);
      minutes %= 60;
      let hours = Math.floor(TotalInseconds / (60 * 60));
      hours %= 24;
      let days = Math.floor(TotalInseconds / (24 * 60 * 60));
      days %= 365;

      if (durationFormat === 'daysHourMinutes') {
        let newResult = null;
        if (days > 0) {
          newResult = `${days}d `;
        }
        if (hours > 0) {
          newResult = `${newResult + hours}h `;
        }
        if (minutes > 0) {
          newResult = `${newResult + minutes}min `;
        }
        if (seconds > 0 && days === 0 && hours === 0 && minutes === 0) {
          newResult = `${newResult + seconds}s `;
        }
        return newResult;
      }
    },
  },
};
