<template>
  <div>
    <h1 class="v-heading text-h3 text-sm-h3 mb-4">gat-grid</h1>
    <p><code>gat-grid</code> is a datatable component based on v-datatable</p>
    <p>
      gat-grid needs as a minimum a data source assigned to the <code>items</code> property and an array of column
      definitions assigned to the <code>columns</code> property.
    </p>

    <gat-code-editor
      value='<gat-grid :items="clients" :columns="columns" title="Client list" @row-clicked="clientSelected" />' />
  </div>
</template>

<script>
export default {
  name: 'ViewGatGridOverview',
  props: [],
  components: {},
  data() {
    return {};
  },

  created() {},

  watch: {},

  methods: {},
};
</script>

<style scoped></style>
