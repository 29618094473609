<template>
  <div class="pl-3">
    <v-list two-line subheader>
      <v-subheader>Events</v-subheader>
      <v-list-item v-for="(event, index) in lastEvents" :key="index">
        <v-list-item-content class="eventContent">
          <v-list-item-title>{{ event.name }}</v-list-item-title>
          <v-list-item-subtitle>{{ event.paramAsJson }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-list-item-action-text v-text="event.triggeredTime"></v-list-item-action-text>
        </v-list-item-action>
        <v-spacer />
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
export default {
  name: 'EventLogger',
  props: { value: Array },
  components: {},
  data() {
    return {};
  },

  created() {},

  watch: {
    value(newValue) {
      // eslint-disable-next-line array-callback-return
      newValue.map((event) => {
        if (!event.triggeredTime) {
          // eslint-disable-next-line no-param-reassign
          event.triggeredTime = this.globalFormatDate(Date.now(), 'HH:mm:ss');
          // eslint-disable-next-line no-param-reassign
          event.paramAsJson = JSON.stringify(event.param);
        }
      });
    },
  },

  computed: {
    lastEvents() {
      const result = [];
      for (let index = 0; index < this.value.length - 1 && index < 4; index++) {
        const event = this.value[index];
        result.push(event);
      }

      return result;
    },
  },

  methods: {},
};
</script>

<style scoped>
.eventContent {
  width: 300px;
}
</style>
