<template>
  <v-app :style="cssProps">
    <main-menu :tab="tab" :showDrawer="drawer" @drawerChanged="drawer = $event" />

    <v-app-bar app color="primary" dark :clipped-left="$vuetify.breakpoint.lgAndUp">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <div class="d-flex align-center">
        <h2>gs-components</h2>
      </div>

      <v-spacer></v-spacer>
    </v-app-bar>

    <v-main>
      <keep-alive>
        <router-view class="pa-4"></router-view>
      </keep-alive>
    </v-main>
  </v-app>
</template>

<script>
import MainMenu from './components/menus/MainMenu.vue';

export default {
  name: 'App',
  components: { MainMenu },

  data: () => ({
    someNumber: 1245.567,
    settings: { dark: false },
    dialog: false,
    drawer: null,
    tab: null,
  }),
  created() {
    // this.$vuetify.theme.dark=true;
  },

  computed: {
    colors() {
      if (this.settings.dark) {
        return { primary: '#EF6C00', groupCaption: '##E0E0E0', gatLink: '#64B5F6', gatMenu: '#303030' };
      }
      return { primary: '#FB8C00', groupCaption: '#757575', gatLink: '#1976D2', gatMenu: '#fafafa' };
    },
    cssProps() {
      return {
        '--gatGroupCaption': this.colors.groupCaption,
        '--gatPrimaryColor': this.colors.primary,
        '--gatLinkColor': this.colors.gatLink,
        '--gatMenuColor': this.colors.gatMenu,
        '--gatSideSheetTop': this.sideSheetTop,
        '--gatDrawerWitdth': this.drawerWith,
      };
    },
  },
};
</script>
