<template>
  <div class="pa-5">
    <h1 class="v-heading text-h3 text-sm-h3 mb-4">gat-grid views</h1>

    <v-layout wrap>
      <gat-grid size="xs6" title="gat-grid" :items="items" :columns="columns" viewGroupName="viewGroupName" />
    </v-layout>

    <br />
    <p>To activate the view menu, a unique name must be assigned to the <code>viewGroupName</code> property.</p>
    <gat-code-editor
      value=' <gat-grid size="xs6" title="gat-grid" :items="items" :columns="columns" viewGroupName="viewGroupName" />'
      language="vue" />
    <br />

    <br />
    <p>The component will try to use the vuex store to retrieve and store the available views.</p>
    <p>There should be a modeule <code>$store.state.gatcomponents</code> with an array <code>gridViews</code></p>
    <v-card>
      <v-card-subtitle>$store.state.gatcomponents.gridViews</v-card-subtitle>

      <record-presenter :value="$store.state.gatcomponents.gridViews" />
    </v-card>
    <br />
  </div>
</template>

<script>
export default {
  name: 'ViewGatGridViews',
  props: [],
  components: {},
  data() {
    return {
      lastMessage: null,
    };
  },

  created() {
    window.messageBus.$on('gscomp-api-post', (param) => {
      console.log('messageBus gscomp-api-post:', param);
      this.lastMessage = param;
    });
    window.messageBus.$on('gscomp-api-delete', (param) => {
      this.lastMessage = param;
      console.log('messageBus gscomp-api-delete:', param);
    });
  },

  watch: {},

  computed: {
    columns() {
      const result = [
        { header: 'Name', field: 'NAME' },
        { header: 'City', field: 'CITY' },
        { header: 'Country', field: 'COUNTRY' },
        { header: 'EU country', field: 'EU', checkbox: true, hideOnPhone: false },
      ];
      return result;
    },
    items() {
      return [
        { ID: 10, NAME: 'GAT-Ship AS', EU: false, COUNTRY: 'Norway', LAST_CALL: '2020/2938', CITY: 'Porsgrunn' },
        { ID: 20, NAME: 'Wilmur Shipping', EU: true, COUNTRY: 'Denmark', LAST_CALL: '2020/1392', CITY: 'Aabenraa' },
        { ID: 40, NAME: 'Shell inc', EU: false, COUNTRY: 'USA', LAST_CALL: '2019/0320', CITY: 'Maine' },
      ];
    },

    viewState() {
      return this.$store.state.gatcomponents.gridViews;
    },
  },

  methods: {
    addOrRemoveFavorite(fav_obj) {
      const idx = this.favorites.findIndex((x) => x.FAV_KEY == fav_obj.ID);
      if (idx >= 0) {
        this.favorites.splice(idx, 1);
      } else {
        this.favorites.push({ FAV_KEY: fav_obj.ID });
      }
    },
  },
};
</script>

<style scoped></style>
