<template>
  <div class="pa-5">
    <h1 class="v-heading text-h3 text-sm-h3 mb-4">
      <a href="#inputs" class="text-decoration-none text-right text-md-left"></a> gat-code-editor
    </h1>
    <p>The <code>gat-code-editor</code> component is used for text field where Syntax highlighting is needed</p>

    <gat-form v-model="validForm" ref="form">
      <v-layout wrap>
        <gat-code-editor
          v-model="editValue"
          :lineNumbers="propValues.lineNumbers"
          :language="propValues.language"
          :readonly="propValues.readonly"
          @blur="$refs.form.validate()" />
      </v-layout>
      <v-divider />
      <gat-edit label="value (v-model)" v-model="editValue" rows="10" size="xl" />
    </gat-form>

    <property-list v-model="propValues" :propList="properties" allowEdit="true" />
  </div>
</template>

<script>
import GatCodeEditor from '@/gat-components/gatInput/GatCodeEdit.vue';

export default {
  name: 'ViewGatCodeEdit',
  props: [],
  components: { GatCodeEditor },
  data() {
    return {
      propValues: {
        lineNumbers: false,
        language: 'sql',
        readonly: false,
      },
      validForm: true,
      editValue: null,
    };
  },

  computed: {
    properties() {
      return [
        {
          name: 'readonly',
          type: 'boolean',
          default: false,
          description: 'Indicates if the editor is read only or not.',
        },
        { name: 'language', type: 'string', default: 'sql', description: 'js, html, sql, vue, ts' },
      ];
    },
  },

  created() {
    this.setEditValue();
  },

  watch: {},

  methods: {
    setEditValue() {
      this.editValue = ` insert into Log_General(CREATED, USER_NAME, OPERATION, TABLE_NAME, PK_VALUE, NEW_VALUE, OLD_VALUE)
                        select getdate(), @username, 'd', @tablename, LOGDATA.ID, NEW.logdata, OLD.logdata  -- Use LOGDATA.UUID, LOGDATA.CODE or LOGDATA.ID depending on the PK type of the actual table
                        from deleted LOGDATA
                        cross apply
                        (select logdata=NULL) as NEW -- will always be NULL for Delete`;
    },
  },
};
</script>

<style scoped></style>
