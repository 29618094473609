<template>
  <div class="propertyList">
    <v-divider />
    <div class="subtitle-1">PROPERTIES</div>
    <v-list dense>
      <v-list-item v-for="prop in propList" :key="prop.name">
        <div class="layout api-item pa-2 wrap">
          <div class="flex xs6 md2">
            <div class="text-capitalize overline text--secondary text--darken-3">name</div>
            <span class="mono name">{{ prop.name }}</span>
          </div>
          <div class="flex xs6 md1 text-xs-right">
            <div class="text-capitalize overline text--secondary text--darken-3">type</div>
            <span class="mono">{{ prop.type }}</span>
          </div>
          <div class="flex xs12 md1">
            <div class="text-capitalize overline text--secondary text--darken-3">default</div>
            <span class="mono">{{ prop.default }}</span>
          </div>
          <div :id="'prop_val_' + prop.name" class="flex xs12 md2 text-md-right" v-if="allowEdit">
            <gat-edit
              v-if="prop.type === 'boolean' || prop.type === 'number' || prop.type === 'string'"
              :label="prop.name"
              size="xl"
              v-model="propValues[prop.name]"
              :checkbox="prop.type == 'boolean'"
              :dataType="getInputType(prop.type)" />
          </div>

          <div class="flex xs12 md6">
            <div class="text-capitalize overline text--secondary text--darken-3">description</div>
            <div class="v-markdown">
              <p>{{ prop.description }}</p>
            </div>
          </div>
        </div>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
export default {
  name: 'PropertyList',
  props: ['allowEdit', 'value', 'propList'],
  components: {},
  data() {
    return {
      propValues: {},
    };
  },

  created() {},

  watch: {
    value: {
      handler(val) {
        this.propValues = val;
      },
      immediate: true,
    },
  },

  methods: {
    getInputType(propType) {
      if (propType == 'number') {
        return 'number';
      }
      return undefined;
    },
  },
};
</script>

<style scoped>
.propertyList {
}

.api-item .mono.name {
  color: #d63200;
}

.mono {
  font-family: Roboto Mono, monospace;
  font-weight: 550;
}
</style>
