<template>
  <v-tabs v-if="!showInToolbar" v-model="activeTabIndex" background-color="orange" color="success" center-active>
    <v-tab v-for="(item, index) in items" :key="index">
      {{ item.caption }}
    </v-tab>
    <!-- index:{{activeTabIndex}} -->
  </v-tabs>
  <!-- {{items}} -->
</template>

<script>
export default {
  name: 'gatTab',
  props: {
    items: Array,
    showInToolbar: Boolean,
  },
  components: {},
  data() {
    return {
      activeTabIndex: 0,
      pagePrams: {},
    };
  },

  activated() {
    this.setActivePageFromPath(this.$route.name);
    // if (this.showInToolbar) {
    //   this.$store.commit('setToolbarTabs', this.items);
    // }
  },

  // deactivated() {
  //   if (this.showInToolbar) {
  //     this.$store.commit('setToolbarTabs', undefined);
  //   }
  // },

  watch: {
    $route(to) {
      if (!this.showInToolbar) {
        this.setActivePageFromPath(to.name);
        this.pagePrams[to.name] = to.params;
      }
    },
    // items(val) {
    //   if (this.showInToolbar) {
    //     this.$store.commit('setToolbarTabs', val);
    //   }
    // },
    activeTabIndex(newIndex) {
      const item = this.items[newIndex];
      if (item.path !== this.$route.path) {
        // console.log('avvik!'+item.path);
        let params = item.routeParams;
        if (!params) {
          params = this.pagePrams[item.routeName];
        }
        this.$router.push({ name: item.routeName, params });
      }
    },
  },

  methods: {
    setActivePageFromPath(name) {
      if (this.items) {
        const index = this.items.findIndex((item) => item.routeName == name);
        if (index >= 0) {
          this.activeTabIndex = index;
        }
      }
    },
  },
};
</script>

<style>
::v-deep .v-slide-group__prev {
  display: none !important;
}
</style>
