<template>
  <div>
    <v-text-field
      :autocomplete="autocomplete"
      :data-lpignore="dataLpignore"
      :data-form-type="dataFormType"
      :clearable="clearable"
      :dense="$store.state.gatcomponents.input_dense"
      :disabled="disabled"
      :error-messages="errorMessages"
      :filled="isFilled"
      :hint="hint"
      :label="getLabel"
      :messages="getMessages"
      :outlined="isOutlined"
      placeholder=" "
      :readonly="readonly"
      ref="field"
      :rules="rules"
      v-model="textValue"
      @blur="onBlur"
      @click:clear="onClearCliked"
      @focus="$event.target.select()"
      v-resize="updateElementWidht">
      <template v-slot:append>
        <span v-if="getSuffixFormat != 'none'" class="mt-1 grey--text">{{ getSuffix() }}</span>
        <v-btn
          v-if="calendarBtnIsVisible"
          small
          icon
          class="calendarBtn"
          @click="pickDateFromCalendar"
          :disabled="readonly || disabled">
          <v-icon small class="grey--text text--darken-1">mdi-calendar</v-icon>
        </v-btn>
      </template>
    </v-text-field>

    <v-dialog ref="dialog" v-model="showDatePicker" :return-value.sync="pickerDate" width="290px">
      <v-date-picker
        v-model="pickerDate"
        no-title
        scrollable
        :first-day-of-week="firstDayOfWeek"
        :show-week="$store.state.gatcomponents.calendarSettings.weekNumberInCalendar != 'none'"
        :locale-first-day-of-year="firstDayofYear"
        @dblclick:date="setDateFromPicker">
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="showDatePicker = false">Cancel</v-btn>
        <v-btn text color="primary" @click="setDateFromPicker()">OK</v-btn>
      </v-date-picker>
    </v-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import { GatInputMixin } from './GatInputMixin';

export default {
  name: 'GatDateEdit',
  props: {
    dense: Boolean,
    clearable: Boolean,
    disabled: Boolean,
    errorMessages: [String, Array],
    hint: String,
    label: String,
    inlineEdit: Boolean,
    readonly: Boolean,
    required: Boolean,
    markOptionalInputs: Boolean,
    futureDate: Boolean,
    showWeekDay: {
      type: String,
      default: 'auto', // auto use dd, ddd or dddd depending on field width when mounted
      validator: (value) => ['none', 'auto', 'dd', 'ddd', 'dddd'].indexOf(value) >= 0,
    },
    value: [String, Object],
    validatorRules: [Function, Array],
    autocomplete: {
      type: String,
      default: 'off',
    },
    dataFormType: {
      type: String,
      default: 'other',
    },
    dataLpignore: {
      type: String,
      default: 'true',
    },
  },
  components: {},
  mixins: [GatInputMixin],
  data() {
    return {
      elWidth: null,
      textValue: '',
      // displayFormat: "DD.MM.YYYY",
      dataFormat: 'YYYY-MM-DD',
      resizeObserver: null, // notifies on element resize
      showDatePicker: false,
      pickerDate: null,
    };
  },

  created() {},

  activated() {
    this.updateElementWidht();
  },

  mounted() {
    if (this.textValue) {
      this.setDate(this.textValue);
    }
    if (this.value) {
      this.setDate(this.value);
    }

    this.resizeObserver = new ResizeObserver(() => {
      this.updateElementWidht();
    });

    this.resizeObserver.observe(this.$refs.field.$el);

    this.updateElementWidht();

    // in dialogs the width change is not trigged, we have to do this timeout hack until a better way is found
    setTimeout(() => {
      this.updateElementWidht();
    }, 300);
  },

  watch: {
    value: {
      handler(newValue) {
        this.setDate(newValue);
      },
      immediate: true,
    },
  },

  computed: {
    calendarBtnIsVisible() {
      let isVisible = this.elWidth > 125;
      isVisible = isVisible && !this.readonly && !this.elementIsReadOnlyByForm();
      return isVisible;
    },

    firstDayofYear() {
      let result = 4;
      if (this.$store.state.gatcomponents.calendarSettings.weekNumberInCalendar == 'us') {
        result = 0;
      }
      return result;
    },
    firstDayOfWeek() {
      let result = 1;
      if (this.$store.state.gatcomponents.calendarSettings.weekStartDay == 'sunday') {
        result = 0;
      } else if (this.$store.state.gatcomponents.calendarSettings.weekStartDay == 'saturday') {
        result = 6;
      }
      return result;
    },
    getHint() {
      let result = this.hint;
      if (!result) {
        result = 'F2=todays date, F9 = show calendar';
      }
      return result;
    },

    isOutlined() {
      return !this.inlineEdit;
    },

    isEditing() {
      return this.textValue != moment(this.textValue, this.displayFormat).format(this.displayFormat);
    },

    isFilled() {
      return this.inlineEdit;
    },

    rules() {
      let rules = [];
      // custom rules
      if (this.validatorRules) {
        rules = rules.concat(this.validatorRules);
      }
      // required rule
      if (this.required) {
        rules.push((value) => {
          if (value) {
            return true;
          }
          return 'required';
        });
      }
      // invlaid date rule
      rules.push(() => {
        if (!this.textValueIsValidDateOrEmpty) {
          return `Invalid date '${this.textValue}'`;
        }
        return true;
      });

      return rules;
    },

    textValueIsValidDateOrEmpty() {
      if (this.textValue === '' || this.textValue == null) {
        return true;
      }
      return this.isValidDate(this.textValue, this.displayFormat);
    },

    displayFormat() {
      return this.$store.state?.settings?.dateFormat ? this.$store.state.settings.dateFormat : 'DD.MM.YYYY';
    },
  },

  methods: {
    elementIsReadOnlyByForm() {
      // Ikke funnet noen god måte å hente readonly status fra v-form.  Sjekker readonly attributtet på input elementet isteden.
      try {
        const readonlyInput = this.$refs.field.$el.querySelector('input[readonly]');
        if (readonlyInput) {
          return true;
        }
      } catch (error) {
        // silent
      }

      return false;
    },

    getSuffix() {
      if (this.isValidDate(this.value, this.dataFormat) && !this.isEditing && this.getSuffixFormat() != 'none') {
        const date = moment(this.value, this.dataFormat);
        return date.format(this.getSuffixFormat());
      }
      return undefined;
    },

    getSuffixFormat() {
      const result = this.showWeekDay;
      let calendarBtnWidth = 0;
      if (this.calendarBtnIsVisible) {
        calendarBtnWidth = 12;
      }
      if (this.showWeekDay == 'auto') {
        if (this.elWidth > 200 + calendarBtnWidth) {
          return 'dddd';
        }
        if (this.elWidth > 150 + calendarBtnWidth) {
          return 'ddd';
        }
        if (this.elWidth > 140 + calendarBtnWidth) {
          return 'dd';
        }
        return 'none';
      }
      return result;
    },

    isValidDate(text, format = this.displayFormat) {
      const date = moment(text, format);
      const result = date.isValid();
      return result;
    },

    onClearCliked() {
      this.$emit('input', null);
    },

    pickDateFromCalendar() {
      if (this.isValidDate(this.value, 'YYYY-MM-DD')) {
        this.pickerDate = this.value;
      } else {
        this.pickerDate = moment(Date.now()).format('YYYY-MM-DD');
      }
      this.showDatePicker = true;
    },

    setDate(newValue) {
      const date = this.textToValidDateTextOrNull(newValue, this.dataFormat);
      if (date == null) {
        this.textValue = '';
      } else {
        this.textValue = moment(date).format(this.displayFormat);
      }
      this.checkForErrors(this.textValue); // mixin
    },

    setDateFromPicker() {
      this.setDate(this.pickerDate);
      this.showDatePicker = false;
      this.onBlur();
    },

    textToValidDateTextOrNull(text, inFormat) {
      const date = moment(text, inFormat);
      if (date.isValid()) {
        if (
          this.futureDate &&
          typeof text === 'string' &&
          text.length <= 2 &&
          date.format(this.dataFormat) !== this.value &&
          date.date() < moment().date()
        ) {
          date.add(1, 'M');
        }
        // console.log(date.date(), new Date().getDate(), date.add(1,'M').format(this.dataFormat))
        return date.format(this.dataFormat);
      }
      return null;
    },

    // on blur, focus leaving edit
    onBlur(eventParams) {
      // find correct date from given text value
      if (this.textValueIsValidDateOrEmpty) {
        const date = this.textToValidDateTextOrNull(this.textValue, this.displayFormat);
        // if found date is different from the value property, emit input event
        if (date != this.value) {
          this.$emit('input', date);
        } // if date is the same as property, but text value is not the same as date, set text value to date
        else if (date != this.textValue && !(date == null && this.textValue == '')) {
          this.textValue = moment(date).format(this.displayFormat);
        }
      }
      this.$emit('blur', eventParams);
    },
    updateElementWidht() {
      this.elWidth = this.$el.getBoundingClientRect().width;
    },
  },
};
</script>

<style scoped>
.calendarBtn {
  margin-right: -10px;
  margin-top: -1px;
}
</style>
