<template>
  <gat-flex v-if="!noflex" :size="size" :hide="hide">
    <gat-field-spacer :size="size">
      <v-combobox
        v-if="combobox"
        :autocomplete="autocomplete"
        :data-lpignore="dataLpignore"
        :data-form-type="dataFormType"
        :autofocus="autofocus"
        :clearable="clearable"
        :dense="$store.state.gatcomponents.input_dense"
        :disabled="disabled"
        :error-messages="errorMessages"
        :filled="isFilled"
        :hint="hint"
        :item-text="textFieldName"
        :items="items"
        :item-value="codeFieldName"
        :label="getLabel"
        :messages="getMessages"
        :outlined="isOutlined"
        :prepend-icon="prependIcon"
        :readonly="isReadonly"
        :rules="rules"
        v-model="itemValue"
        @blur="$emit('blur')"
        @change="updateValue()"
        @input="updateValue()"
        @update:search-input="changeHandler"></v-combobox>
      <v-select
        v-else-if="noSearch"
        :autocomplete="autocomplete"
        :data-lpignore="dataLpignore"
        :data-form-type="dataFormType"
        :autofocus="autofocus"
        :clearable="clearable"
        :dense="$store.state.gatcomponents.input_dense"
        :disabled="disabled"
        :error-messages="errorMessages"
        :filled="isFilled"
        :filter="filter"
        :hint="hint"
        :items="items"
        :item-text="textFieldName"
        :item-value="codeFieldName"
        :label="getLabel"
        :messages="getMessages"
        :outlined="isOutlined"
        placeholder=" "
        :prepend-icon="prependIcon"
        :readonly="isReadonly"
        :rules="rules"
        v-model="itemValue"
        @blur="$emit('blur')"
        @input="updateValue()"
        @update:search-input="searchValue = $event">
        <template v-if="useIcons || columns" v-slot:item="data">
          <v-icon v-if="useIcons" :class="data.item.iconClass" class="pr-2">{{ data.item.icon }}</v-icon>
          <span v-if="useIcons">{{ data.item[textFieldName] }}</span>
          <gat-select-item v-if="columns" :columns="columnsWithWith" :item="data.item" :searchValue="searchValue" />
        </template>
        <template v-else-if="customItem" v-slot:item="data">
          <slot name="item" v-bind:data="data.item">
            {{ data.item[textFieldName] }}
          </slot>
        </template>
        <template v-if="useIcons" v-slot:selection="data">
          <v-icon :class="data.item.iconClass" class="pr-2">{{ data.item.icon }}</v-icon>
          <span>{{ data.item[textFieldName] }}</span>
        </template>
        <template v-else-if="customSelection" v-slot:selection="data">
          <slot name="selection" v-bind:data="data.item">
            {{ data.item[textFieldName] }}
          </slot>
        </template>
      </v-select>
      <v-autocomplete
        v-else
        :autocomplete="autocomplete"
        :data-lpignore="dataLpignore"
        :data-form-type="dataFormType"
        :autofocus="autofocus"
        :clearable="clearable"
        :dense="$store.state.gatcomponents.input_dense"
        :disabled="disabled"
        :error-messages="errorMessages"
        :filled="isFilled"
        :filter="filter"
        :hint="hint"
        :items="items"
        :item-text="textFieldName"
        :item-value="codeFieldName"
        :label="getLabel"
        :messages="getMessages"
        :outlined="isOutlined"
        placeholder=" "
        :prepend-icon="prependIcon"
        :readonly="isReadonly"
        :rules="rules"
        v-model="itemValue"
        @blur="$emit('blur')"
        @input="updateValue()"
        @update:search-input="searchValue = $event">
        <template v-if="useIcons || columns" v-slot:item="data">
          <v-icon v-if="useIcons" :class="data.item.iconClass" class="pr-2">{{ data.item.icon }}</v-icon>
          <span v-if="useIcons">{{ data.item[textFieldName] }}</span>
          <gat-select-item v-if="columns" :columns="columnsWithWith" :item="data.item" :searchValue="searchValue" />
        </template>
        <template v-else-if="customItem" v-slot:item="data">
          <slot name="item" v-bind:data="data.item">
            {{ data.item[textFieldName] }}
          </slot>
        </template>
        <template v-if="useIcons" v-slot:selection="data">
          <v-icon :class="data.item.iconClass" class="pr-2">{{ data.item.icon }}</v-icon>
          <span>{{ data.item[textFieldName] }}</span>
        </template>
        <template v-else-if="customSelection" v-slot:selection="data">
          <slot name="selection" v-bind:data="data.item">
            {{ data.item[textFieldName] }}
          </slot>
        </template>
        <!-- <template v-if="columns" v-slot:item="data">
          <gat-select-item :columns="columnsWithWith" :item="data.item" :searchValue="searchValue" />
        </template> -->
      </v-autocomplete>
    </gat-field-spacer>
  </gat-flex>

  <span v-else>
    <v-combobox
      v-if="combobox"
      :autofocus="autofocus"
      :clearable="clearable"
      :dense="$store.state.gatcomponents.input_dense"
      :disabled="disabled"
      :error-messages="errorMessages"
      :filled="isFilled"
      :items="items"
      :hint="hint"
      :item-text="textFieldName"
      :item-value="codeFieldName"
      :label="getLabel"
      :messages="getMessages"
      :outlined="isOutlined"
      :prepend-icon="prependIcon"
      :readonly="isReadonly"
      :rules="rules"
      v-model="itemValue"
      @blur="$emit('blur')"
      @change="updateValue()"
      @input="updateValue()"
      @update:search-input="changeHandler"></v-combobox>
    <v-select
      v-else-if="noSearch"
      :autofocus="autofocus"
      :clearable="clearable"
      :dense="$store.state.gatcomponents.input_dense"
      :disabled="disabled"
      :error-messages="errorMessages"
      :filled="isFilled"
      :filter="filter"
      :hint="hint"
      :items="items"
      :item-text="textFieldName"
      :item-value="codeFieldName"
      :label="getLabel"
      :messages="getMessages"
      :outlined="isOutlined"
      placeholder=" "
      :prepend-icon="prependIcon"
      :readonly="isReadonly"
      :rules="rules"
      v-model="itemValue"
      @blur="$emit('blur')"
      @input="updateValue()"
      @update:search-input="searchValue = $event">
      <template v-if="useIcons || columns" v-slot:item="data">
        <v-icon v-if="useIcons" :class="data.item.iconClass" class="pr-2">{{ data.item.icon }}</v-icon>
        <span v-if="useIcons">{{ textFieldName ? data.item[textFieldName] : data.item.text }}</span>
        <gat-select-item v-if="columns" :columns="columnsWithWith" :item="data.item" :searchValue="searchValue" />
      </template>

      <template v-if="useIcons" v-slot:selection="data">
        <v-icon :class="data.item.iconClass" class="pr-2">{{ data.item.icon }}</v-icon>
        <span>{{ textFieldName ? data.item[textFieldName] : data.item.text }}</span>
      </template>
    </v-select>
    <v-autocomplete
      v-else
      :autofocus="autofocus"
      :clearable="clearable"
      :dense="$store.state.gatcomponents.input_dense"
      :disabled="disabled"
      :error-messages="errorMessages"
      :hint="hint"
      :filled="isFilled"
      :items="items"
      :item-text="textFieldName"
      :item-value="codeFieldName"
      :label="getLabel"
      :messages="getMessages"
      :outlined="isOutlined"
      :prepend-icon="prependIcon"
      :readonly="isReadonly"
      :rules="rules"
      v-model="itemValue"
      @blur="$emit('blur')"
      @input="updateValue()"></v-autocomplete>
  </span>
</template>

<script>
import GatFlex from '../GatFlex.vue';
import GatFieldSpacer from '../GatFieldSpacer.vue';
import GatSelectItem from './GatSelectItem.vue';
import { GatInputMixin } from './GatInputMixin';

export default {
  name: 'GatSelect',
  components: { GatFlex, GatFieldSpacer, GatSelectItem },
  mixins: [GatInputMixin],
  props: {
    autofocus: Boolean,
    clearable: Boolean,
    codeFieldName: String,
    combobox: Boolean,
    columns: Array,
    customSelection: Boolean,
    customItem: Boolean,
    disabled: Boolean,
    errorMessages: [Array, String],
    hide: Boolean,
    hint: String,
    inlineEdit: Boolean,
    items: Array,
    label: String,
    markOptionalInputs: Boolean,
    noflex: Boolean,
    noSearch: Boolean,
    prependIcon: String,
    readonly: Boolean,
    required: Boolean,
    size: String,
    textFieldName: {
      type: String,
      default: 'text',
    },
    value: [String, Number, Boolean, Object],
    useIcons: Boolean,
    autocomplete: {
      type: String,
      default: 'off',
    },
    dataFormType: {
      type: String,
      default: 'other',
    },
    dataLpignore: {
      type: String,
      default: 'true',
    },
  },
  data() {
    return {
      itemValue: undefined,
      searchValue: null,
      item: {},
    };
  },

  watch: {
    value: {
      handler(newValue) {
        this.itemValue = newValue;
        this.setItem(newValue, this.items);
        if (!this.itemValue) {
          this.item = {};
        }
        if (this.checkForErrors(newValue)) {
          this.expandParentGatGroup(); // mixin
        }
      },
      immediate: true,
    },
    items(val) {
      this.setItem(this.itemValue, val);
    },
  },

  mounted() {
    if (this.value) {
      this.itemValue = this.value;
      this.setItem(this.itemValue, this.items);
    } else {
      this.item = {};
    }
  },

  computed: {
    columnsWithWith() {
      if (this.columns) {
        // eslint-disable-next-line array-callback-return
        this.columns.map((col) => {
          let maxWidth = 10;
          // eslint-disable-next-line array-callback-return
          this.items.map((item) => {
            const value = item[col.field];
            if (value) {
              const w = this.getTextWidth(value, '14px Roboto sans-serif') + 32;
              if (w > maxWidth) {
                maxWidth = w;
              }
            }
          });
          // eslint-disable-next-line no-param-reassign
          col.width = maxWidth;
        });
      }
      return this.columns;
    },

    getLabel() {
      let result = this.label;
      if (this.getRequiredOrOptionalHint != '') {
        result += '*';
      }
      return result;
    },
    getMessages() {
      const result = this.getRequiredOrOptionalHint;
      if (result != '') {
        return result;
      }
      return undefined;
    },
    getRequiredOrOptionalHint() {
      let result = '';
      // onlys how for empty fields
      if (this.itemValue != null) {
        return '';
      }
      // normally hint is shown on required field only
      if (this.required) {
        result = '*required';
      }

      // if most fields are required, we can insted mark the optional fields
      if (this.markOptionalInputs) {
        if (this.required) {
          result = '';
        } else {
          result = '*optional';
        }
      }
      return result;
    },

    isOutlined() {
      return !this.inlineEdit;
    },

    isFilled() {
      return this.inlineEdit;
    },

    isReadonly() {
      if (typeof this.readonly != 'undefined') {
        return this.readonly;
      }
      return false;
      // return this.getGroupReadonly();
    },

    rules() {
      const rules = [];
      // required rule
      if (this.required) {
        rules.push((value) => {
          if (value || value == 0) {
            return true;
          }
          return 'required';
        });
      }

      return rules;
    },
  },

  methods: {
    changeHandler(text) {
      // due to an error in the combobox component the change event is not called when the text is changed in a combobox
      this.$emit('input', text);
    },
    filter(item, queryText, itemText) {
      if (this.columns) {
        let result = false;
        for (let index = 0; index < this.columns.length; index++) {
          const element = item[this.columns[index].field];
          if (element) {
            result = element.toLowerCase().includes(queryText.toLowerCase());
          }
          if (result) {
            return true;
          }
        }
        return result;
      }
      return itemText.toLowerCase().includes(queryText.toLowerCase());
    },

    getTextWidth(text, font) {
      // re-use canvas object for better performance
      const canvas = this.getTextWidth.canvas || (this.getTextWidth.canvas = document.createElement('canvas'));
      const context = canvas.getContext('2d');
      context.font = font;
      const metrics = context.measureText(text);
      return Math.round(metrics.width) + 1;
    },

    setItem(codeValue, items) {
      let codeName = this.codeFieldName;
      if (!this.codeFieldName) {
        codeName = 'value';
      }
      if (codeValue && items && items.length > 0) {
        const item = items.find((x) => x[codeName] == codeValue);
        if (item && item[codeName] != this.item[codeName]) {
          this.item = item;
          this.$emit('item-selected', item);
        }
      }
    },

    updateValue() {
      if (typeof this.itemValue === 'undefined') {
        this.itemValue = null;
      }
      this.setItem(this.itemValue, this.items);
      this.$emit('input', this.itemValue);
    },
  },
};
</script>

<style scoped></style>
