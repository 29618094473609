<template>
  <gat-dialog
    :value="showEditor"
    title="Grid view settings"
    persistent
    fullscreen
    okButtonCaption="save"
    deleteButton
    okButtonIsProminent
    @cancelClick="cancelClicked"
    @okClick="saveClicked">
    <gat-group title="View name and settings" class="mt-2" :compact="true">
      <v-layout wrap>
        <gat-edit v-model="view.name" label="Name" :maxChars="100" size="xs12 sm6 md3" />
        <gat-edit v-model="view.isMyDefault" label="My default view" checkbox size="xs12 sm6 md3" />
        <gat-edit
          v-if="$store.getters.userRight('G.GLOBAL_GRID_LAYOUT')"
          v-model="view.isGlobal"
          label="Global view"
          checkbox
          size="xs12 sm6 md3" />
        <gat-edit
          :disabled="!view.isGlobal"
          v-model="view.isGlobalDefault"
          label="Global default view"
          checkbox
          size="xs12 sm6 md3" />
      </v-layout>
    </gat-group>
    <gat-group title="Columns included in view" class="pb-2 tableColumnsGroup" collapsed="true">
      <template slot="compact">
        <draggable v-model="usedColumns" group="compcols" @start="drag = true" @end="drag = false">
          <v-chip label small outlined class="mr-1 mb-1" v-for="(col, index) in usedColumns" :key="index">{{
            col.header
          }}</v-chip>
        </draggable>
      </template>
      <div>
        Drag and drop to select and reorder columns in your table. <br />
        Click the group icon ( <v-icon>mdi-group</v-icon> ) to group by a certain column<br />
        Click on the phone icon ( <v-icon>mdi-cellphone</v-icon> ) to toggle phone visibility
      </div>
      <v-layout wrap class="mt-2">
        <v-flex xs6 sm6 md4 lg3>
          <div class="ml-1 text-subtitle-2">Columns included</div>
          <draggable v-model="usedColumns" group="graphs" @start="drag = true" @end="drag = false">
            <v-flex v-for="col in usedColumns" :key="col.field" class="dashBorder mb-1 mr-1">
              <gat-grid-view-column-obj
                :value="col"
                :available="false"
                @groupedBy="toggleGroupedBy(col)"
                @colRemoved="removeColumn(col)" />
            </v-flex>
          </draggable>
        </v-flex>
        <v-flex xs6 sm6 md4 lg3>
          <div class="ml-4 text-subtitle-2">Available columns</div>
          <draggable :list="availableColumns" group="graphs" @start="drag = true" @end="drag = false">
            <v-flex v-for="(col, index) in availableColumns" :key="index" class="dashBorder mb-1 ml-1">
              <gat-grid-view-column-obj :value="col" :available="true" @colAdded="addColumn(col)" />
            </v-flex>
          </draggable>
        </v-flex>
      </v-layout>
    </gat-group>

    <gat-group title="Sorting" class="pb-2" collapsed="true">
      <template slot="compact">
        <draggable v-model="sortedBy" group="sortcols" @start="drag = true" @end="drag = false">
          <v-chip label small outlined class="mr-1 mb-1" v-for="(col, index) in sortedBy" :key="index">
            <span class="mr-4">{{ index + 1 }}. {{ col.header }}</span>
            <v-btn
              text
              icon
              color="blue"
              small
              class="descbtn"
              @click.stop="toggleColSort(col)"
              right
              v-show="!col.desc"
              ><v-icon small class="grey--text">mdi-sort-alphabetical-ascending</v-icon></v-btn
            >
            <v-btn text icon color="blue" small class="descbtn" @click.stop="toggleColSort(col)" right v-show="col.desc"
              ><v-icon small class="grey--text">mdi-sort-alphabetical-descending</v-icon></v-btn
            >
          </v-chip>
        </draggable>
      </template>
      <div>Drag and drop to reorder the column sort sequense</div>
      <v-layout wrap class="mt-2">
        <v-flex xs12 class="mb-2">
          <draggable v-model="sortedBy" group="sortcols" @start="drag = true" @end="drag = false">
            <v-chip label outlined class="mr-1 mb-1" v-for="(col, index) in sortedBy" :key="index">
              <span class="pt-1 mr-7">{{ index + 1 }}. {{ col.header }}</span>
              <v-btn text icon color="blue" class="descbtn" @click="toggleColSort(col)" right v-show="!col.desc"
                ><v-icon class="grey--text">mdi-sort-alphabetical-ascending</v-icon></v-btn
              >
              <v-btn text icon color="blue" class="descbtn" @click="toggleColSort(col)" right v-show="col.desc"
                ><v-icon class="grey--text">mdi-sort-alphabetical-descending</v-icon></v-btn
              >
            </v-chip>
          </draggable>
        </v-flex>
        <v-flex xs12 class="mt-2 mb-1">
          <div>Select the columns you wants to sort by</div>
        </v-flex>
        <v-flex xs6 sm6 md4 lg3>
          <div class="ml-4 mb-1 text-subtitle-2">Columns in view</div>
          <v-flex v-for="(col, index) in usedColumns" :key="index" class="ml-1 pl-3">
            <v-switch
              v-if="col.field && col.field != 'GRID_LINE_NO'"
              dense
              :input-value="isColSortedBy(col)"
              :label="col.header"
              checkbox
              class="sortcheckbox"
              @change="sortByToggled($event, col)" />
          </v-flex>
        </v-flex>
        <v-flex xs6 sm6 md4 lg3>
          <div class="ml-4 mb-1 text-subtitle-2">Columns not in view</div>
          <v-flex v-for="(col, index) in availableColumns" :key="index" class="ml-1 pl-3">
            <v-switch
              dense
              :input-value="isColSortedBy(col)"
              :label="col.header"
              checkbox
              class="sortcheckbox"
              @change="sortByToggled($event, col)" />
          </v-flex>
        </v-flex>
      </v-layout>
    </gat-group>

    <gat-group title="Filter" class="pb-2" :collapsed="true">
      <template slot="compact">
        <span
          v-if="
            !(
              view.filters &&
              ((view.filters.and && view.filters.and.length > 0) || (view.filters.or && view.filters.or.length > 0))
            )
          "
          >none</span
        >
        <div v-else>
          <span v-if="bothFilterTypesUsed">( </span>
          <span v-for="(filter, index) in view.filters.and" :key="filter.id">
            <span v-if="index > 0" class="ml-2"> AND </span
            ><v-chip small class="ml-1 mb-1">{{ getFilterDescription(filter) }} </v-chip>
          </span>
          <span v-if="bothFilterTypesUsed"> ) AND ( </span>
          <span v-for="(filter, index) in view.filters.or" :key="filter.id">
            <span v-if="index > 0" class="ml-2"> OR </span
            ><v-chip small class="ml-1">{{ getFilterDescription(filter) }} </v-chip>
          </span>
          <span v-if="bothFilterTypesUsed"> )</span>
        </div>
      </template>
      <v-layout wrap>
        <gat-sub-group title="Meet ALL of the following conditions" size="xs12 xl6">
          <v-flex v-if="view.filters" class="test">
            <gat-grid-view-filter-item
              v-for="filter in view.filters.and"
              :key="filter.id"
              :items="items"
              :columns="columns"
              :orgFilter="filter"
              @removeFilter="removeFilter"
              @changed="filterChanged" />
          </v-flex>
          <v-flex xs12 class="mt-2">
            <v-btn small outlined color="primary" class="ml-5" @click="addFilter('and')"
              ><v-icon left>mdi-plus</v-icon> condition</v-btn
            >
          </v-flex>
        </gat-sub-group>
        <gat-sub-group title="Meet ANY of the following conditions" size="xs12 xl6">
          <v-flex v-if="view.filters" class="test">
            <gat-grid-view-filter-item
              v-for="filter in view.filters.or"
              :key="filter.id"
              :items="items"
              :columns="columns"
              :orgFilter="filter"
              @removeFilter="removeFilter"
              @changed="filterChanged" />
          </v-flex>
          <v-flex xs12 class="mt-2">
            <v-btn small outlined color="primary" class="ml-5" @click="addFilter('or')"
              ><v-icon left>mdi-plus</v-icon> condition</v-btn
            >
          </v-flex>
        </gat-sub-group>
      </v-layout>
    </gat-group>

    <gat-group title="Footer" class="pb-2" collapsed="true">
      <template slot="compact">
        <v-chip label small outlined class="mr-1 mb-1" v-for="(col, index) in footerCols" :key="index">
          <span class="text-uppercase font-weight-medium">{{ col.footerFunction.fn }}</span
          >( '{{ col.header }}' )
        </v-chip>
      </template>
      <v-layout wrap class="mt-2">
        <v-flex xs12 class="mt-2 mb-1">
          <div>Select the columns you wants include in the footer</div>
        </v-flex>
        <v-flex xs12 lg6 xl4>
          <v-card outlined class="ml-5 mr-3 pl-2" v-for="(col, index) in usedColumns" :key="index">
            <v-layout wrap>
              <gat-edit :value="isFooter(col)" :label="col.header" :toggle="true" @input="footerToggled($event, col)" />
              <gat-select
                size="xs4"
                v-if="col.footerFunction && isFooter(col)"
                :value="col.footerFunction.fn"
                @input="footerFunctionChanged($event, col)"
                label="Function"
                :items="getFooterFunctions()" />
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
    </gat-group>
    <!-- <record-presenter :value="view.filters" /> -->

    <gat-group v-if="false" title="data" :collapsed="true">
      <v-layout wrap>
        <record-presenter :value="usedColumns" />
        <record-presenter :value="availableColumns" />
      </v-layout>
    </gat-group>

    <v-card-actions>
      <v-btn text @click="deleteDialog = true">Delete</v-btn>
      <v-spacer></v-spacer>
      <!-- <v-btn color="success" text @click="cancelClicked">Cancel</v-btn>
                <v-btn color="success" @click="saveClicked">Save</v-btn> -->
    </v-card-actions>

    <v-dialog v-model="deleteDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="title">Delete this view?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="deleteDialog = false">Cancel</v-btn>
          <v-btn text color="primary" @click="deleteClicked">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </gat-dialog>
</template>

<script>
import draggable from 'vuedraggable';
import GatGridViewColumnObj from './GatGridViewColumnObj.vue';
import GatGridViewFilterItem from './GatGridViewFilterItem.vue';

export default {
  name: 'GatGridViewEditor',
  props: ['viewGroupName', 'name', 'columns', 'viewToEdit', 'items', 'showEditor'],
  components: { draggable, GatGridViewColumnObj, GatGridViewFilterItem },
  data() {
    return {
      deleteDialog: false,
      view: {
        name: '',
      },
      usedColumns: [],
      availableColumns: [],
      sortedBy: [],
      footerCols: [],
      test: true,
    };
  },

  created() {},

  computed: {
    bothFilterTypesUsed() {
      const result =
        this.view.filters &&
        this.view.filters.and &&
        this.view.filters.and.length > 0 &&
        this.view.filters.or &&
        this.view.filters.or.length > 0;
      return result;
    },

    goupedByCols() {
      const result = [];
      // eslint-disable-next-line array-callback-return
      this.usedColumns.map((col) => {
        result.push(col.groupedBy);
      });
      return result;
    },

    goupedByAllCols() {
      const result = [];
      // eslint-disable-next-line array-callback-return
      this.columns.map((col) => {
        result.push(col.groupedBy);
      });
      return result;
    },

    used() {
      const result = [];
      for (let index = 0; index < this.usedColumns.length; index++) {
        const element = this.usedColumns[index];
        result.push(`${index}.${element.header}`);
      }
      return result;
    },
  },

  watch: {
    columns: {
      handler(val) {
        // eslint-disable-next-line array-callback-return
        val.map((col) => {
          if (!Object.prototype.hasOwnProperty.call(col, 'groupedBy')) {
            this.$set(col, 'groupedBy', false);
          }
        });
        this.setAvailableColumns(val, val);
      },
      immediate: true,
    },

    viewToEdit: {
      handler(val) {
        this.view = val;
        if (val.columns) {
          this.setIncludedColumnsFromView(val.columns);
        } else {
          const cols = [];
          // eslint-disable-next-line array-callback-return
          this.columns.map((item) => {
            if (!item.hide) {
              cols.push(item);
            }
          });
          this.usedColumns = cols;
        }
        if (val.sortedBy) {
          this.sortedBy = val.sortedBy;
        } else {
          this.sortedBy = [];
        }
        if (val.footerCols) {
          this.footerCols = val.footerCols;
        } else {
          this.footerCols = [];
        }
      },
      immediate: true,
    },
  },

  methods: {
    addColumn(col) {
      this.usedColumns.push(col);
      const idx = this.availableColumns.findIndex((item) => item.field == col.field);
      if (idx >= 0) {
        this.availableColumns.splice(idx, 1);
      }
    },

    removeColumn(col) {
      this.availableColumns.push(col);
      const idx = this.usedColumns.findIndex((item) => item.field == col.field);
      if (idx >= 0) {
        this.usedColumns.splice(idx, 1);
      }
    },

    addFilter(filterType) {
      const newView = this.duplicateViaJson(this.view);
      if (!newView.filters) {
        newView.filters = { and: [], or: [] };
      }
      newView.filters[filterType].push({ id: this.GUID() });
      this.view = newView; // pga filter kanskje ikke ekstisterer (reactive)
    },

    cancelClicked() {
      this.$emit('closeClicked', { action: 'cancel' });
    },

    deleteClicked() {
      this.$store.commit('removeGatGridView', this.view);
      this.$emit('closeClicked', { action: 'deleted', view: this.view });
    },

    filterChanged() {},

    footerFunctionChanged(fn, col) {
      // col.footerFunction.fn = fn;
      const footerCol = this.footerCols.find((item) => item.field == col.field);
      if (footerCol) {
        footerCol.footerFunction.fn = fn;
      }
    },

    footerToggled(use, col) {
      const idx = this.footerCols.findIndex((item) => {
        let id = 'field';
        if (!Object.prototype.hasOwnProperty.call(item, id)) {
          id = 'header';
        }
        return item[id] == col[id];
      });

      if (use) {
        if (idx < 0) {
          this.$set(col, 'footerFunction', { fn: 'sum' });
          this.footerCols.push(col);
        }
      } else if (idx >= 0) {
        this.footerCols.splice(idx, 1);
      }
      this.footerCols.sort((a, b) => {
        const idxA = this.usedColumns.findIndex((x) => x.field == a.field);
        const idxB = this.usedColumns.findIndex((x) => x.field == b.field);
        return idxA - idxB;
      });
    },

    getFilterDescription(filter) {
      let result = '';
      const columnHeader = this.lookupCode(filter.columnName, this.columns, 'field', 'header');
      result = `${result}'${columnHeader}' ${filter.comparer}`;
      if (typeof filter.matchValue != 'undefined') {
        if (filter.comparer.includes('x days')) {
          result = result.replace('x days', `${filter.matchValue} days`);
        } else {
          result = `${result} ${filter.matchValue}`;
        }
      }
      return result;
    },

    getFooterFunctions() {
      const result = ['sum', 'count', 'count unique', 'average', 'max', 'min'];

      return result;
    },

    isColSortedBy(col) {
      const idx = this.sortedBy.findIndex((item) => {
        let id = 'field';
        if (!Object.prototype.hasOwnProperty.call(item, id)) {
          id = 'header';
        }
        return item[id] == col[id];
      });
      return idx >= 0;
    },

    isFooter(col) {
      const idx = this.footerCols.findIndex((item) => {
        let id = 'field';
        if (!Object.prototype.hasOwnProperty.call(item, id)) {
          id = 'header';
        }
        return item[id] == col[id];
      });
      return idx >= 0;
    },

    saveClicked() {
      this.view.columns = this.usedColumns;
      for (let index = 0; index < this.view.columns.length; index++) {
        this.view.columns[index].index = index;
      }
      this.view.viewGroupName = this.viewGroupName;
      this.view.sortedBy = this.sortedBy;
      this.view.footerCols = this.footerCols;
      this.$store.commit('setGatGridView', this.view);
      this.$emit('closeClicked', { action: 'saved', view: this.view });
    },

    sortByToggled(use, col) {
      const idx = this.sortedBy.findIndex((item) => {
        let id = 'field';
        if (!Object.prototype.hasOwnProperty.call(item, id)) {
          id = 'header';
        }
        return item[id] == col[id];
      });

      if (use) {
        if (idx < 0) {
          this.$set(col, 'desc', false);
          this.sortedBy.push(col);
        }
      } else if (idx >= 0) {
        this.sortedBy.splice(idx, 1);
      }
    },

    toggleGroupedBy(col) {
      const cols = this.usedColumns.concat();
      // eslint-disable-next-line array-callback-return
      cols.map((usedCol) => {
        if (usedCol.field == col.field) {
          // eslint-disable-next-line no-param-reassign
          usedCol.groupedBy = !usedCol.groupedBy;
        } else {
          // eslint-disable-next-line no-param-reassign
          usedCol.groupedBy = false;
        }
      });

      this.usedColumns = cols;
    },

    removeFilter(filter) {
      let index = this.view.filters.and.findIndex((item) => item.id == filter.id);

      if (index >= 0) {
        this.view.filters.and.splice(index, 1);
      } else {
        index = this.view.filters.or.findIndex((item) => item.id == filter.id);
        if (index >= 0) {
          this.view.filters.or.splice(index, 1);
        }
      }
    },

    setAvailableColumns(orgColumns, usedColumns) {
      const visCols = [];
      const availCols = [];
      // eslint-disable-next-line array-callback-return
      orgColumns.map((orgCol) => {
        const usedCol = usedColumns.find((item) => {
          let id = 'name';
          if (!Object.prototype.hasOwnProperty.call(orgCol, id)) {
            id = 'field';
          }
          return Object.prototype.hasOwnProperty.call(item, id) && item[id] == orgCol[id];
        });
        if (usedCol && !usedCol.hide) {
          visCols.push(this.duplicateViaJson(usedCol));
        } else {
          availCols.push(this.duplicateViaJson(orgCol));
        }
        visCols.sort((a, b) => a.index - b.index);
      });
      this.usedColumns = visCols;
      this.availableColumns = availCols;
    },

    setIncludedColumnsFromView(viewCols) {
      const visCols = [];
      const availCols = [];
      // eslint-disable-next-line array-callback-return
      this.columns.map((orgCol) => {
        if (!Object.prototype.hasOwnProperty.call(orgCol, 'groupedBy')) {
          this.$set(orgCol, 'groupedBy', false);
        }

        const viewCol = viewCols.find((item) => {
          let id = 'name';
          if (!Object.prototype.hasOwnProperty.call(orgCol, id)) {
            id = 'field';
          }
          return Object.prototype.hasOwnProperty.call(item, id) && item[id] == orgCol[id];
        });
        if (viewCol) {
          if (!Object.prototype.hasOwnProperty.call(viewCol, 'groupedBy')) {
            this.$set(viewCol, 'groupedBy', false);
          }
          visCols.push(this.duplicateViaJson(viewCol));
        } else {
          availCols.push(this.duplicateViaJson(orgCol));
        }
        visCols.sort((a, b) => a.index - b.index);
      });

      this.usedColumns = visCols;
      this.availableColumns = availCols;
    },

    toggleColSort(col) {
      // eslint-disable-next-line no-param-reassign
      col.desc = !col.desc;
    },
  },
};
</script>

<style scoped>
.test {
  max-width: 800px;
}
.sortcheckbox {
  margin: 0px !important;
  padding: 0px;
}

.descbtn {
  margin-top: 1px;
  right: 0px;
  position: absolute !important;
}
</style>
