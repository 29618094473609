<template>
  <div>
    <v-text-field
      ref="field"
      :autocomplete="autocomplete"
      :data-lpignore="dataLpignore"
      :data-form-type="dataFormType"
      v-model="textValue"
      :clearable="clearable"
      :disabled="disabled"
      :label="getLabel"
      :messages="getMessages"
      @blur="onBlur"
      :error-messages="errorMessages"
      :error="error"
      :readonly="readonly"
      :hint="hint"
      placeholder=" "
      :outlined="isOutlined"
      :filled="isFilled"
      :dense="$store.state.gatcomponents.input_dense"
      :rules="rules"
      @click:clear="onClearCliked"
      @focus="[$event.target.select(), $emit('focus', $event)]">
      <template slot="append">
        <slot name="append"></slot>
      </template>
    </v-text-field>
  </div>
</template>

<script>
import moment from 'moment';
import { GatInputMixin } from './GatInputMixin';

export default {
  name: 'GattimeEdit',
  props: {
    clearable: Boolean,
    value: [String, Number, Boolean, Object],
    label: String,
    disabled: Boolean,
    error: Boolean,
    readonly: Boolean,
    hint: String,
    inlineEdit: Boolean,
    required: Boolean,
    errorMessages: [String, Array],
    validatorRules: [Function, Array],
    autocomplete: {
      type: String,
      default: 'off',
    },
    dataFormType: {
      type: String,
      default: 'other',
    },
    dataLpignore: {
      type: String,
      default: 'true',
    },
  },
  components: {},
  mixins: [GatInputMixin],
  data() {
    return {
      textValue: '',
      timeFormat: 'HH:mm',
    };
  },

  watch: {
    value: {
      handler(newValue) {
        this.setTextValue(newValue);
      },
      immediate: true,
    },
  },

  computed: {
    isOutlined() {
      return !this.inlineEdit;
    },

    isFilled() {
      return this.inlineEdit;
    },

    rules() {
      let rules = [];
      // custom rules
      if (this.validatorRules) {
        rules = rules.concat(this.validatorRules);
      }
      // required rule
      if (this.required) {
        rules.push((value) => {
          if (value) {
            return true;
          }
          return 'required';
        });
      }

      return rules;
    },
  },

  mounted() {
    if (this.textValue) {
      this.setTextValue(this.textValue);
    }
    if (this.value) {
      this.setTextValue(this.value);
    }
  },

  methods: {
    focus() {
      this.$refs.field.focus();
    },
    onClearCliked() {
      this.$emit('input', null);
    },

    setTextValue(value) {
      const patt = /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/;
      const isoDate = patt.exec(value);
      if (isoDate) {
        this.textValue = moment.utc(value).format(this.timeFormat);
      } else {
        this.textValue = this.textToValidTimeTextOrNull(value);
      }
      this.checkForErrors(this.textValue); // mixin
    },

    textToValidTimeTextOrNull(text) {
      const time = moment(text, this.timeFormat);
      if (time.isValid()) {
        return time.format(this.timeFormat);
      }
      return null;
    },

    // on blur, focus leaving edit
    onBlur(eventParams) {
      const time = this.textToValidTimeTextOrNull(this.textValue);
      if (time != this.value) {
        this.$emit('input', time);
      }
      this.$emit('blur', eventParams);
    },
  },
};
</script>

<style scoped></style>
