import Vue from 'vue';

const gatComponentsMessageBus = new Vue({});

export const GlobalComponentMixin = {
  data() {
    return {
      gatComponentsMessageBus,
    };
  },
  methods: {
    lookupCode(code, list, codeField = 'value', textField = 'text') {
      let result = code;
      if (list && list.length > 0) {
        // eslint-disable-next-line array-callback-return, consistent-return
        const record = list.find((item) => {
          if (item[codeField] == code) {
            return item;
          }
        });
        if (record) {
          result = record[textField];
        }
      }

      return result;
    },
  },
};
