<template>
  <div class="pa-5">
    <h1 class="v-heading text-h3 text-sm-h3 mb-4">gat-grid slots</h1>
    <p>There are two slots available for the gat-grid:</p>
    <p><code>btns</code>: to add buttons to the top right corner of the grid</p>
    <p><code>cell</code>: to override the default rendering of the cell content</p>
    <br />
    <v-layout wrap>
      <gat-grid :items="items" :columns="columns">
        <template v-if="props.column.field == 'COUNTRY'" slot="cell" slot-scope="props">
          <span v-if="props.value == 'Norway'">Local</span>
          <span v-else>Foreign</span>
          <v-icon>mdi-flag</v-icon>
        </template>
      </gat-grid>
    </v-layout>
    <br />
    <gat-code-editor
      value='  <gat-grid  :items="items" :columns="columns">
                <template v-if="props.column.field==`COUNTRY`" slot="cell" slot-scope="props">
                    <span v-if="props.value==`Norway`">Local</span>
                    <span v-else>Foreign</span>
                    <v-icon>mdi-flag</v-icon>
                </template>
            </gat-grid>'
      language="js" />
  </div>
</template>

<script>
export default {
  name: 'ViewGatGridSlots',
  props: [],
  components: {},
  data() {
    return {};
  },

  created() {},

  watch: {},

  computed: {
    columns() {
      const result = [
        { header: 'Name', field: 'NAME' },
        { header: 'Country', field: 'COUNTRY' },
        { header: 'EU country', field: 'EU' },
      ];
      return result;
    },
    items() {
      return [
        { ID: 10, NAME: 'GAT-Ship AS', EU: false, COUNTRY: 'Norway', LAST_CALL: '2020/2938' },
        { ID: 20, NAME: 'Wilmur Shipping', EU: true, COUNTRY: 'Denmark', LAST_CALL: '2020/1392' },
        { ID: 40, NAME: 'Shell inc', EU: false, COUNTRY: 'USA', LAST_CALL: '2019/0320' },
      ];
    },
  },

  methods: {},
};
</script>

<style scoped></style>
