<template>
  <div class="pa-5">
    <h1 class="v-heading text-h3 text-sm-h3 mb-4">gat-grid properties</h1>
    <gat-form>
      <v-layout wrap>
        <gat-grid
          :items="items"
          :columns="columns"
          :dense="propValues.dense"
          :draggableRows="propValues.draggableRows"
          :height="propValues.height"
          :hideEmptyColumns="propValues.hideEmptyColumns"
          :fixedHeader="true"
          :orgSortBy="[{ header: 'Name' }, { field: 'ID', desc: true }]"
          :reloadBtn="propValues.reloadBtn"
          :selectable="propValues.selectable"
          :tight="propValues.tight"
          :title="propValues.title"
          :keyName="propValues.keyName"
          :noPadding="propValues.noPadding"
          :rowBar="propValues.rowBar"
          :rowBarClass="propValues.rowBarClass"
          :stickyFirstColumn="propValues.stickyFirstColumn"
          :viewGroupName="propValues.viewGroupName">
        </gat-grid>
      </v-layout>
    </gat-form>
    <br />
    <property-list v-model="propValues" :propList="properties" allowEdit="true" />
  </div>
</template>

<script>
export default {
  name: 'ViewGatGridProperties',
  props: [],
  components: {},
  data() {
    return {
      propValues: {
        title: 'Client list',
        keyName: 'ID',
        fixedHeader: true,
        rowBarClass: 'sailed-bg',
        stickyFirstColumn: false,
        noPadding: false,
      },
    };
  },

  computed: {
    columns() {
      const result = [
        { header: 'Name', field: 'NAME' },
        { header: 'Country', field: 'COUNTRY' },
        { header: 'EU country', field: 'EU', checkbox: true, hideOnPhone: false },
        { header: 'Last call', linkUrl: '/last_call/field[ID]', linkText: 'field[LAST_CALL]' },
        { header: 'Value x', field: 'X' },
      ];
      return result;
    },
    items() {
      return [
        { ID: 10, NAME: 'GAT-Ship AS', EU: false, COUNTRY: 'Norway', LAST_CALL: '2020/2938' },
        { ID: 20, NAME: 'Wilmur Shipping', EU: true, COUNTRY: 'Denmark', LAST_CALL: '2020/1392' },
        { ID: 40, NAME: 'Shell inc', EU: false, COUNTRY: 'USA', LAST_CALL: '2019/0320' },
      ];
    },
    properties() {
      return [
        { name: 'columns', type: 'array', default: [], description: 'definition of columns' },
        {
          name: 'colsClass',
          type: 'string',
          default: 'undefined',
          description: 'css class name applied to all columns',
        },
        { name: 'dense', type: 'boolean', default: false, description: 'makes the list more dense' },
        {
          name: 'draggableRows',
          type: 'boolean',
          default: false,
          description: 'The grid will support dragNDrop of rows',
        },
        {
          name: 'favorites',
          type: 'array',
          default: 'undefined',
          description:
            'a list of favourites.  An icon (star) will be show, and the user can add and remove favourites.',
        },
        {
          name: 'fixedheader',
          type: 'boolean',
          default: false,
          description: 'fixed header to top of table. NOTE: Does not work in IE11',
        },
        {
          name: 'height',
          type: 'string',
          default: 'undefined',
          description: 'sets the height property of the v-datatable',
        },
        {
          name: 'hideEmptyColumns',
          type: 'boolean',
          default: false,
          description: 'hide columns were there is no data',
        },
        { name: 'items', type: 'array', default: [], description: 'the data source as an array of objects' },
        {
          name: 'keyName',
          type: 'string',
          default: 'undefined',
          description:
            'the name of a field identifying the row item.  Must be set when selectable or favorites is used.',
        },
        { name: 'noPadding', type: 'boolean', default: false, description: '?' },
        {
          name: 'noSelectAll',
          type: 'boolean',
          default: false,
          description: 'Hides the select all checbox in the header',
        },
        {
          name: 'orgSortBy',
          type: 'array',
          default: [],
          description:
            "array of sort selection using the header or field to identify the column. example value: [{header:'Name'},{field:'ID',desc:true}]",
        },
        {
          name: 'reloadBtn',
          type: 'boolean',
          default: false,
          description:
            'set to true to show a refresh/reload btn behind the grid title (title required).  When clicked will trigger the reload-clicked event',
        },
        {
          name: 'rowclass',
          type: 'string',
          default: 'undefined',
          description:
            'css class name or a function.  If a function, the function gets rowData as paramter, and should return a the css class name.',
        },
        {
          name: 'rowBar',
          type: 'boolean',
          default: false,
          description: 'set to true to add a rowbar (used in the whiteboard)',
        },
        {
          name: 'rowBarClass',
          type: 'string',
          default: 'undefined',
          description:
            'css class name or a function to set color of rowbar (background color).  If a function, the function gets rowData as paramter, and should return a the css class name.',
        },
        {
          name: 'selectable',
          type: 'boolean',
          default: false,
          description: 'set to true to show checkbox for selecting rows',
        },
        {
          name: 'selectedKeys',
          type: 'array',
          default: 'undefined',
          description: 'can be used to set what row should be selected',
        },
        {
          name: 'stickyFirstColumn',
          type: 'boolean',
          default: false,
          description: 'set to true to make the first column fixed when scrolling horizontally',
        },
        { name: 'tight', type: 'boolean', default: false, description: 'use litle air between the columns' },
        { name: 'title', type: 'string', default: 'undefined', description: 'title / caption shown above the grid' },
        {
          name: 'viewGroupName',
          type: 'string',
          default: 'undefined',
          description: 'if set, a view menu will be shown, and the user can defined grid views',
        },
      ];
    },
  },

  created() {},

  watch: {},

  methods: {},
};
</script>

<style>
.sailed-bg {
  background-color: rgb(153, 153, 231) !important;
}
</style>
