<template>
  <div class="pa-5">
    <h1 class="v-heading text-h3 text-sm-h3 mb-4">gat-button</h1>
    <p><code>gat-button</code> is a component used by the <code>gat-button-group</code> component.</p>
    <p>Its can be used directly when a button with dropown menu is needed.</p>

    <gat-button
      :caption="propValues.caption"
      :disabled="propValues.disabled"
      :hideCaptionOnPhone="propValues.hideCaptionOnPhone"
      :icon="propValues.icon"
      :mainAction="propValues.mainAction"
      :menuItems="menuItems"
      :openMenuOnHover="propValues.openMenuOnHover"
      @menuItemClick="menuItemClicked" />
    <gat-button
      caption="add"
      icon="mdi-plus"
      :hideCaptionOnPhone="propValues.hideCaptionOnPhone"
      mainAction
      @click="buttonClicked"
      userRight="RO.ADD" />

    <gat-code-editor
      value="<gat-button
            caption='Add from template'
            icon='mdi-plus-box-multiple'
            :menuItems='getMenuItems'
            @menuItemClick='addFromTemplate'
            />
    ----
        menuItems=[ {caption:'All',icon:'mdi-expand-all'},
                    {caption:'Some',icon:'mdi-plus-box-multiple'},
                    {caption:'one',icon:'mdi-plus-box'}
                    {caption:'sub menu',useDividerBefore:true, menuItems:[
                            {caption:'sub item 1',icon:'mdi-numeric-1-box'},
                            {caption:'sub item 2',icon:'mdi-numeric-2-box'},
                            {caption:'sub item 3',icon:'mdi-numeric-3-box'},]}
                ]

            "
      language="js"
      :readonly="true" />

    <event-list :value="events" />

    <property-list v-model="propValues" :propList="properties" allowEdit="true" />

    <v-snackbar :timeout="3000" v-model="showSnackbar" right top color="success" outlined>
      <h4>{{ snackbarText }}</h4>
      <br />
      {{ eventParam }}
    </v-snackbar>
  </div>
</template>

<script>
import GatButton from '../gat-components/gatButtons/GatButton.vue';

export default {
  name: 'ViewGatButton',
  props: [],
  components: { GatButton },
  data() {
    return {
      propValues: {
        caption: 'add from template',
        disabled: false,
        icon: 'mdi-plus-box-multiple',
        hideCaptionOnPhone: true,
        openMenuOnHover: true,
        mainAction: false,
      },
      snackbarText: '',
      showSnackbar: false,
      eventParam: {},
    };
  },

  created() {},

  watch: {},

  computed: {
    events() {
      return [
        { name: 'click', type: 'object', description: 'button was clicked' },
        {
          name: 'menuItemClick',
          type: 'object',
          description: 'a item in the button menu was clicked. The menu item is event parameter',
        },
      ];
    },

    menuItems() {
      return [
        { caption: 'All', icon: 'mdi-expand-all' },
        { caption: 'Some', icon: 'mdi-plus-box-multiple' },
        { caption: 'one', icon: 'mdi-plus-box' },
        {
          caption: 'sub menu',
          useDividerBefore: true,
          menuItems: [
            { caption: 'sub item 1', icon: 'mdi-numeric-1-box' },
            { caption: 'sub item 2', icon: 'mdi-numeric-2-box' },
            { caption: 'sub item 3', icon: 'mdi-numeric-3-box' },
          ],
        },
      ];
    },

    properties() {
      return [
        { name: 'caption', type: 'string', default: 'undefined', description: 'Caption for button' },
        {
          name: 'disabled',
          type: 'boolean',
          default: false,
          description: 'Set to true to disable button.  If userRight is set, userRight might also disable the button',
        },
        {
          name: 'hideCaptionOnPhone',
          type: 'boolean',
          default: false,
          description: 'Set to true to only show icon in mobile device',
        },
        { name: 'icon', type: 'string', default: 'undefined', description: 'Ex: mdi-delete' },
        {
          name: 'mainAction',
          type: 'boolean',
          default: false,
          description: 'Set if btn is main action.  Only one btn should be main action',
        },
        {
          name: 'menuCaption',
          type: 'string',
          default: 'undefined',
          description: 'Set to use an alternative caption if button is used in a menu (phone)',
        },
        { name: 'menuItems', type: 'array', default: 'undefined', description: 'Set to make a drop down button.  ' },
        {
          name: 'openMenuOnHover',
          type: 'boolean',
          default: true,
          description: 'Set if the menu should be shown on hover.  Will not work with sub-menues',
        },
        {
          name: 'userRight',
          type: 'atring',
          default: 'undefined',
          description: 'User right needed for enabling the button. Ex: CH.NEW_AND_EDIT',
        },
      ];
    },
  },

  methods: {
    buttonClicked() {
      this.showSnackbar = true;
      this.eventParam = null;
      this.snackbarText = 'Button add was clicked';
    },

    menuItemClicked(param) {
      this.showSnackbar = true;
      this.eventParam = param;
      this.snackbarText = `Menu item '${param.caption}' clicked`;
    },
  },
};
</script>

<style scoped></style>
