<template>
  <div class="pa-5">
    <h1 class="v-heading text-h3 text-sm-h3 mb-4">gat-grid columns</h1>
    <p>Columns setup are done by passing an array of objects to the gat-grid property <code>columns</code></p>

    <v-layout wrap>
      <gat-grid :items="items" :columns="columns" />
    </v-layout>

    <gat-code-editor :value="columnsAsJson" />

    <property-list v-model="propValues" :propList="properties" :allowEdit="false" />
  </div>
</template>

<script>
export default {
  name: 'ViewGatGridColumns',
  props: [],
  components: {},
  data() {
    return {
      propValues: {},
    };
  },

  created() {},

  watch: {},

  computed: {
    columnsAsJson() {
      let result = 'columns = [\n';
      // eslint-disable-next-line array-callback-return
      this.columns.map((col) => {
        if (col.header == 'Calc example') {
          result =
            `${result}{"header":"Calc example","calculated": (rowData)=>{var d = new Date(); return rowData.ID+"-"+d.getSeconds()}},` +
            `\n`;
        } else {
          result = `${result + JSON.stringify(col)}\n`;
        }
      });
      return `${result}     ]`;
    },

    columns() {
      const result = [
        { header: 'Name', field: 'NAME', width: '300px', footerFunction: { fn: 'count' } },
        {
          header: 'Calc example',
          calculated: (rowData) => {
            const d = new Date();
            return `${rowData.ID}-${d.getSeconds()}`;
          },
        },
        { header: 'Cntr', field: 'COUNTRY_CODE', phoneOnly: true },
        { header: 'Country', field: 'COUNTRY', hideOnPhone: true },
        { header: 'EU country', field: 'EU', checkbox: true, hideOnPhone: true },
        { header: 'Start date', field: 'DATE', dataType: 'date', datetimeFormat: 'DD.MMM YYYY' },
        { header: 'Last call', linkUrl: '/last_call/field[ID]', linkText: 'field[LAST_CALL]' },
        { header: 'Tall', field: 'TALL', maxDecimals: 3, footerFunction: { fn: 'sum' } },
        {
          header: 'Tall2',
          field: 'MERE_TALL',
          dataType: 'number',
          numberFormat: '0,0.00',
          footerFunction: { fn: 'sum' },
        },
        { header: 'Duration', field: 'DURATION_MS', dataType: 'duration', footerFunction: { fn: 'sum' } },
        { header: 'Value x', field: 'X' },
      ];
      return result;
    },
    items() {
      return [
        {
          ID: 10,
          NAME: 'GAT-Ship AS',
          EU: false,
          COUNTRY: 'Norway',
          COUNTRY_CODE: 'NO',
          LAST_CALL: '2020/2938',
          TALL: 123.456789,
          DURATION_MS: 12003,
        },
        {
          ID: 20,
          NAME: 'Wilmur Shipping',
          EU: true,
          COUNTRY: 'Denmark',
          COUNTRY_CODE: 'DK',
          LAST_CALL: '2020/1392',
          DATE: '2020-05-17',
          MERE_TALL: 2103.5971,
          DURATION_MS: 331823,
        },
        {
          ID: 40,
          NAME: 'Shell inc',
          EU: false,
          COUNTRY: 'United States of America',
          COUNTRY_CODE: 'US',
          LAST_CALL: '2019/0320',
          TALL: 100,
          DURATION_MS: 123821382,
        },
      ];
    },
    properties() {
      return [
        {
          name: 'calculated',
          type: 'string',
          default: 'undefined',
          description:
            'a function that receives rowData as parameter, and returns the value to be displayed in the cell. The rowData is the item object for the row.',
        },
        {
          name: 'calculatedClass',
          type: 'string',
          default: 'undefined',
          description: 'a function that takes a rowItem as parameter and return a class name for styling the cell',
        },
        {
          name: 'checkbox',
          type: 'boolean',
          default: false,
          description: 'will display a checked icon in the cell for true values',
        },
        {
          name: 'datetimeFormat',
          type: 'string',
          default: 'undefined',
          description: 'Formatstring for date, time and datetime cols.  If not set using GatCommon.xxxDisplaysettings',
        },
        {
          name: 'dataType',
          type: 'string',
          default: 'undefined',
          description:
            'set to date or datetime to get dates formated in the grid.  currency to get two decimals.  duraton and number is nother options',
        },
        {
          name: 'field',
          type: 'string',
          default: 'undefined',
          description: 'field/property name in data source (items)',
        },
        {
          name: 'fieldWhenCopying',
          type: 'string',
          default: 'undefined',
          description: 'will be used instead of field when copying to clipboard',
        },
        { name: 'header', type: 'string', default: 'undefined', description: 'header caption' },
        {
          name: 'hindeOnPhone',
          type: 'boolean',
          default: false,
          description: 'will hide the column on mobile devices (unless rotated)',
        },
        {
          name: 'ignoreOnCopy',
          type: 'boolean',
          default: false,
          description: 'the column will not be included when coping the grid to clipboard',
        },
        {
          name: 'inkText',
          type: 'string',
          default: 'undefined',
          description:
            'will make the content of the cell a link.  field[fieldname] can be used to show values from the datasource',
        },
        {
          name: 'linkUrl',
          type: 'string',
          default: 'undefined',
          description:
            'The url of the link for the cell.  field[fieldname] can be used to show values from the datasource',
        },
        {
          name: 'maxDecimals',
          type: 'number',
          default: 'undefined',
          description: 'limit decimals shown. Used with dataType="number"',
        },
        {
          name: 'numberFormat',
          type: 'string',
          default: 'undefined',
          description: 'More flexible number formating option. Used with dataType="number"',
        },
        {
          name: 'nowrap',
          type: 'boolean',
          default: false,
          description: 'Set to true to prevent wrapping of text in the column (auto set on numbers and date/times)',
        },
        { name: 'width', type: 'number', default: 'undefined', description: 'Set fixed width for the column' },
        {
          name: 'footerFunction',
          type: 'string',
          default: 'undefined',
          description:
            " Set to add footer sum ('sum','count','average','max','min,'sumMilliseconds'). postText can be used to add currency text. Syntax: 'footerFunction':{'fn':'sum', 'preText':'SUM: ', 'postText':' NOK'} ",
        },
        {
          name: 'phoneOnly',
          type: 'boolean',
          default: false,
          description: 'will only show the column on mobile devices (unless rotated).',
        },
      ];
    },
  },

  methods: {},
};
</script>

<style scoped></style>
