<template>
  <div>
    <h1 class="v-heading text-h3 text-sm-h3 mb-4">gat-button-group</h1>
    <p><code>gat-button-group</code> is a component used to manage a row of buttons.</p>
    <p>
      For frequently used buttons, like add and delete, there is a standardized setup. But most importantly, the
      component will handle mobile device rendering, where most buttons will be a meny item
    </p>

    <gat-group title="advanced example" :collapsed="false">
      <template slot="btn">
        <gat-button-group :buttons="getAdvButtons" @click="buttonClicked" @addFromTemplateClick="buttonClicked" />
      </template>
      <gat-code-editor
        value='<gat-btn-group buttons="getButtons"
        @addAllClick="addAllTemplate"
        @addSomeClick="addSomeTemplate"
        @addAllClick="addOneTemplate"
        >
    </gat-btn-group>'
        language="vue"
        :readonly="true" />

      <gat-code-editor
        value="computed:{
        getButtons(){
            return [
               {btnType:'CUSTOM',icon:'mdi-plus',caption:'Add from template',mainAction:true, embeddMenuItems:true, useSubTitle:true
                        menuItems:[
                            {caption:'All',icon:'mdi-expand-all',eventName:'addAllClick'},
                            {caption:'Some',icon:'mdi-plus-box-multiple',eventName:'addSomeClick'},
                            {caption:'one',icon:'mdi-plus-box',eventName:'addOneClick'}
                        ]
                    },
                {btnType:'DELETE'},
            ];
        }
    }"
        language="js"
        :readonly="true" />
    </gat-group>

    <gat-group title="example" :collapsed="false">
      <template slot="btn">
        <gat-button-group :buttons="getButtons" @click="buttonClicked" />
      </template>
      <gat-code-editor
        value='<gat-btn-group buttons="getButtons"
                @addClick="addItem"
                @deleteClick="deleteItem"
                @specialBtnClick="visRarMelding">
    </gat-btn-group>'
        language="vue"
        :readonly="true" />

      <gat-code-editor
        value="computed:{
        getButtons(){
            return [
                {btnType:gatButtons.ADD,userRight:'CH.NEW_AND_EDIT',showOnPhone:true},
                {btnType:gatButtons.DELETE,userRight:'CH.DELETE', disabled:!this.userCanDelete},
                {btnType:gatButtons.CUSTOM,icon:'mdi-plus-box-multiple',caption:'Special btn',eventName:'specialBtnClick', userRight:'CH.SPECIAL'},
            ];
        }
    }"
        language="js"
        :readonly="true" />
    </gat-group>

    <v-snackbar :timeout="8000" v-model="showSnackbar" right top color="success" outlined vertical>
      <h4>{{ snackbarText }}</h4>
      <br />
      {{ lastBtn }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="showSnackbar = false"> Close </v-btn>
      </template>
    </v-snackbar>
    <event-list :value="events" />
    <property-list :value="{}" :propList="properties" allowEdit="false" />
  </div>
</template>

<script>
import GatButtonGroup from '../gat-components/gatButtons/GatButtonGroup.vue';

export default {
  name: 'ViewGatGroup',
  props: [],
  components: { GatButtonGroup },
  data() {
    return {
      snackbarText: 'button clicked',
      showSnackbar: false,
      lastBtn: {},
    };
  },

  created() {},

  watch: {},

  computed: {
    getAdvButtons() {
      return [
        {
          btnType: 'CUSTOM',
          icon: 'mdi-plus',
          caption: 'Add from template',
          mainAcstion: true,
          embeddMenuItems: true,
          useSubTitle: true,
          menuItems: [
            { caption: 'All', icon: 'mdi-expand-all', eventName: 'addAllClick' },
            { caption: 'Some', icon: 'mdi-plus-box-multiple', eventName: 'addSomeClick' },
            { caption: 'one', icon: 'mdi-plus-box', eventName: 'addOneClick' },
          ],
        },
        { btnType: 'DELETE' },
      ];
    },

    getButtons() {
      return [
        { btnType: 'ADD', userRight: 'CH.NEW_AND_EDIT', showOnPhodne: true },
        { btnType: 'DELETE', userRight: 'CH.DELETE', disabled: true },
        {
          btnType: 'CUSTOM',
          icon: 'mdi-shape-plus',
          caption: 'Special btn',
          eventName: 'specialBtnClicked',
          userRight: 'CH.SPECIAL',
        },
      ];
    },

    events() {
      return [
        {
          name: 'click',
          type: 'object',
          description: 'button/menu item was clicked. Object parameter: {caption,button,menuItem (optional)}',
        },
        {
          name: 'xxx',
          type: 'none',
          description:
            'custom named event is called when a button/menu item is clicked.  The eventName property of the button/menu item is used as event name',
        },
      ];
    },

    properties() {
      return [
        {
          name: 'buttons',
          type: 'Array',
          default: '[]',
          description: 'Array of button definition.  See gat-button for general button object properties',
        },
        {
          name: 'rightAligned',
          type: 'Boolean',
          default: 'true',
          description: 'Button group is aligned to the right (will also reverese button order)',
        },
        {
          name: 'button.embeddMenuItems',
          type: 'Booelan',
          default: 'false',
          description:
            'If set on a button with meny, there will not be a sub menu on phones.  The menu items will handled as if they where buttons',
        },
        {
          name: 'button.useSubTitle',
          type: 'Booelan',
          default: 'false',
          description:
            'If set on a button with meny and where embeddedMenuItems is set, there will be a sub title before the menu embedded menu items',
        },
      ];
    },
  },

  methods: {
    buttonClicked(eventParam) {
      if (eventParam.caption) {
        this.snackbarText = `${eventParam.caption} clicked`;
      } else {
        this.snackbarText = 'click event';
      }
      this.lastBtn = eventParam;
      this.showSnackbar = true;
    },
  },
};
</script>

<style scoped></style>
