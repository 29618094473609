<template>
  <div class="pa-5">
    <h1 class="v-heading text-h3 text-sm-h3 mb-4">gat-select</h1>
    <p>The <code>gat-select</code> component is used for input-select/dropdown</p>
    <p>
      The gat-select needs a data source assigned to the items property.<br />
      Set textFieldName to control what data is displayed in the drop down, and set codeFieldName to control what data
      is used as value.
    </p>

    <gat-code-editor
      value='<gat-select v-model="role.VHO_ROLE_ID" :items="roles" label="Role" textFieldName="NAME" codeFieldName="ID" :errorMessages="roleSelectErrors"> </gat-select>'
      language="vue"
      :readonly="true" />
    <gat-form v-model="validForm" ref="form">
      <v-layout wrap>
        <gat-select
          v-model="editValue"
          :clearable="propValues.clearable"
          :codeFieldName="propValues.codeFieldName"
          :columns="false"
          :combobox="propValues.combobox"
          :disabled="propValues.disabled"
          :errorMessages="propValues.errorMessages"
          :hint="propValues.hint"
          :label="propValues.label"
          :inlineEdit="propValues.inlineEdit"
          :items="propValues.items"
          :noflex="propValues.noflex"
          :prependIcon="propValues.prependIcon"
          :readonly="propValues.readonly"
          :required="propValues.required"
          :size="propValues.size"
          :textFieldName="propValues.textFieldName"
          :useIcons="propValues.useIcons"
          @blur="$refs.form.validate()"
          @item-selected="selectedValue = $event" />

        <span class="ml-4">
          <div class="text-capitalize overline text--secondary text--darken-3">value</div>
          {{ editValue }}
        </span>

        <span class="ml-4">
          <div class="text-capitalize overline text--secondary text--darken-3">datatype</div>
          {{ typeof editValue }}
        </span>

        <span class="ml-4">
          <div class="text-capitalize overline text--secondary text--darken-3">selected-item</div>
          {{ selectedValue }}
        </span>
      </v-layout>
    </gat-form>

    <event-list :value="events" />
    <property-list v-model="propValues" :propList="properties" allowEdit="true" />
  </div>
</template>

<script>
export default {
  name: 'ViewGatSelect',
  props: [],
  components: {},
  data() {
    return {
      selectedValue: null,
      selectedItems: null,
      propValues: {
        useIcons: false,
        readonly: false,
        disabled: false,
        errorMessages: null,
        hint: 'give me a hint',
        label: 'My select input',
        codeFieldName: 'ID',
        textFieldName: 'NAME',
        items: [
          { ID: 10, NAME: 'Not handled', icon: 'mdi-form-select', iconClass: 'red--text', class: 'red--text' },
          { ID: 20, NAME: 'Pending', icon: 'mdi-list-status', iconClass: 'blue--text' },
          { ID: 40, NAME: 'Closed', icon: 'mdi-cards-heart', iconClass: 'green--text' },
        ],
      },
      validForm: true,
      editValue: null,
    };
  },

  created() {
    this.setEditValue();
  },

  watch: {},

  computed: {
    events() {
      return [
        {
          name: 'item-selected',
          type: 'object',
          description: 'gives the object that was selected',
        },
      ];
    },

    properties() {
      return [
        {
          name: 'autofocus',
          type: 'boolean',
          default: false,
          description: 'set to true, to set the focus to this field automatically',
        },
        {
          name: 'codeFieldName',
          type: 'string',
          default: 'text',
          description: 'field/property name in data source (items) used as value for the drop down',
        },
        {
          name: 'columns',
          type: 'array',
          default: null,
          description:
            'by setting an array of column deffinitions the drop down can show data from the datasource,  columns={field:"Id",width:30},{field:"Description",width:100}',
        },
        {
          name: 'combobox',
          type: 'boolean',
          default: false,
          description:
            'will allow the user to enter text using a v-combobox instead of a v-autocomplete with fixed items',
        },
        {
          name: 'clearable',
          type: 'boolean',
          default: false,
          description: 'shows an X to clear the content of the field (only implemented on text fields)',
        },
        { name: 'disabled', type: 'boolean', default: false, description: 'disables the field' },
        {
          name: 'errorMessages',
          type: 'string',
          default: 'undefined',
          description: 'set the input in an error state and shows the message below the input',
        },
        {
          name: 'hint',
          type: 'string',
          default: 'undefined',
          description: 'adds a hint to the input (text below the iput when the input has focus)',
        },
        { name: 'inlineEdit', type: 'boolean', default: false, description: 'Used for inputs in grids' },
        { name: 'items', type: 'array', default: [], description: 'the datasource - an array of objects' },
        { name: 'label', type: 'string', default: 'undefined', description: 'field label / caption' },
        {
          name: 'noflex',
          type: 'boolean',
          default: false,
          description: 'Used for inputs in grids (removes the gat-flex component)',
        },
        {
          name: 'noSearch',
          type: 'boolean',
          default: false,
          description: 'Will make a select without lookup functionallity',
        },
        { name: 'prependIcon', type: 'string', default: false, description: 'Adds an icon before the input field' },
        {
          name: 'readonly',
          type: 'boolean',
          default: false,
          description: 'set to true if user is not alloed to change the input',
        },
        { name: 'required', type: 'boolean', default: false, description: 'set to true if not allowed to be empty' },
        { name: 'size', type: 'string', default: 'm', description: 'xs s sm m l xl' },
        {
          name: 'textFieldName',
          type: 'string',
          default: 'value',
          description: 'field/property name in data source (items) displayed to the user',
        },
        {
          name: 'useIcons',
          type: 'boolean',
          default: false,
          description: 'if set, will show an icon if defined in datasource as field "icon"',
        },
      ];
    },
  },

  methods: {
    setEditValue() {
      this.editValue = null;
    },
  },
};
</script>

<style scoped></style>
