import moment from 'moment';

const GatCommon = {
  datetimeDisplaySettings: {
    dateFormat: 'DD.MMM YYYY',
    timeFormat: 'HH:mm',
    datetimeFormat: 'DD.MMM YYYY HH:mm',
  },
  datetimeEditSettings: {
    dateFormat: 'DD.MM.YYYY',
    timeFormat: 'HH:mm',
    datetimeFormat: 'DD.MM.YYYY HH:mm',
  },

  methods: {
    formatDisplayDate(date) {
      return moment(date).format(GatCommon.datetimeDisplaySettings.dateFormat);
    },
  },
};

export default GatCommon;
