<template>
  <div class="pa-5">
    <h1 class="v-heading text-h3 text-sm-h3 mb-4">gat-grid menu</h1>
    <gat-code-editor
      value='<gat-grid  :items="items" :columns="columns" :rowMenu="menuItems" @rowMenuClicked="rowMenuClicked" />'
      language="vue" />

    <v-layout wrap>
      <gat-grid :items="items" :columns="columns" :rowMenu="rowMenu" @rowMenuClicked="showEventParams" />
    </v-layout>
    <br />

    <p>
      To add a right-click menu to the grid, assign an array of menu items to the <code>rowMenu</code> property and an
      event handler to the <code>rowMenuClicked</code> event
    </p>
    <gat-code-editor
      value='let menuItems = [
                    {name:"properties", caption:"properties...",icon:"mdi-playlist-edit",enabled:true},
                    {name:"export", caption:"export...",icon:"mdi-export",visible:(rowItem)=>{return rowItem.EXPORTED!=1}},
                    {name:"delete", caption:"delete...",icon:"delete",enabled:allowDelete},
                    ];

                    //Only allow deletion og unexported records
                    allowDelete(rowItem){
                        if(rowItem.EXPORTED){
                            return false
                        } else{
                            return $store.getters.userRights("TE.DELETE");
                        }
                    }'
      language="js" />

    <br />
    <p>The<code>rowMenuClicked</code> event will send a both the menu-item and the row-item as parameter</p>
    <gat-code-editor
      value='{
    "menuItem":{"name":"properties","caption":"properties...","icon":"mdi-playlist-edit","enabled":true},
    "rowItem":{"ID":20,"NAME":"Wilmur Shipping","EU":true,"COUNTRY":"Denmark","LAST_CALL":"2020/1392"}
    }'
      language="js" />

    <br />
    menu-item properties:
    <property-list :propList="properties" :allowEdit="false" />
  </div>
</template>

<script>
export default {
  name: 'ViewGatGridmenu',
  props: [],
  components: {},
  data() {
    return {};
  },

  created() {},

  watch: {},
  computed: {
    properties() {
      return [
        { name: 'name', type: 'string', default: 'undefined', description: 'menu item name' },
        { name: 'caption', type: 'string', default: 'undefined', description: 'menu item caption' },
        { name: 'icon', type: 'string', default: 'undefined', description: 'icon name' },
        {
          name: 'enabled',
          type: 'boolean',
          default: true,
          description:
            'set to false to disable the menu item.  A function returning true or false can be used as value, the params to the function will be a rowItem',
        },
        {
          name: 'visible',
          type: 'boolean',
          default: true,
          description:
            'set to false to hide the menu item.  A function returning true or false can be used as value, the params to the function will be a rowItem',
        },
      ];
    },

    columns() {
      const result = [
        { header: 'Name', field: 'NAME' },
        { header: 'Country', field: 'COUNTRY' },
        { header: 'EU country', field: 'EU', checkbox: true },
        { header: 'Exported', field: 'EXPORTED', checkbox: true },
      ];
      return result;
    },
    items() {
      return [
        { ID: 10, NAME: 'GAT-Ship AS', EU: false, COUNTRY: 'Norway', LAST_CALL: '2020/2938', EXPORTED: 0 },
        { ID: 20, NAME: 'Wilmur Shipping', EU: true, COUNTRY: 'Denmark', LAST_CALL: '2020/1392', EXPORTED: 0 },
        { ID: 40, NAME: 'Shell inc', EU: false, COUNTRY: 'USA', LAST_CALL: '2019/0320', EXPORTED: 1 },
      ];
    },
    rowMenu() {
      return [
        { name: 'properties', caption: 'properties...', icon: 'mdi-playlist-edit', enabled: true },
        { name: 'export', caption: 'export...', icon: 'mdi-export', visible: (rowItem) => rowItem.EXPORTED != 1 },
        { name: 'delete', caption: 'delete...', icon: 'delete', enabled: false },
      ];
    },
  },

  methods: {
    showEventParams(params) {
      console.log(params);
    },
  },
};
</script>

<style scoped></style>
