<template>
  <div class="text-center">
    <v-menu offset-y>
      <template v-slot:activator="{ on }">
        <div v-if="!iconOnly">
          <v-btn text small color="grey darken-1" v-on="on">
            {{ viewBtnCaption }}
            <v-icon right>mdi-chevron-down</v-icon>
          </v-btn>
        </div>
        <div v-else>
          <v-btn icon v-on="on">
            <v-icon small>mdi-cog</v-icon>
          </v-btn>
        </div>
      </template>
      <v-list>
        <v-list-item @click="addView"
          ><v-list-item-icon><v-icon>mdi-plus</v-icon></v-list-item-icon>
          <v-list-item-content><v-list-item-title>Add view</v-list-item-title></v-list-item-content>
        </v-list-item>
        <v-list-item @click="editView" v-if="currentView"
          ><v-list-item-icon><v-icon>mdi-pencil</v-icon></v-list-item-icon>
          <v-list-item-content
            ><v-list-item-title>Edit '{{ currentView.name }}'</v-list-item-title></v-list-item-content
          >
        </v-list-item>
        <v-list-item @click="setView(null)">
          <v-list-item-icon><v-icon>mdi-cancel</v-icon></v-list-item-icon>
          <v-list-item-title>No view</v-list-item-title>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item v-for="(item, index) in views" :key="index" @click="setView(item)">
          <v-list-item-icon
            ><v-icon v-if="currentView && currentView.name == item.name">mdi-check</v-icon></v-list-item-icon
          >
          <v-list-item-title>{{ item.name }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <gat-grid-view-editor
      :showEditor="showViewEditor"
      :viewGroupName="viewGroupName"
      :viewToEdit="view"
      @closeClicked="viewEditorClosed"
      :columns="orgColumns"
      :items="items" />
  </div>
</template>

<script>
import GatGridViewEditor from './GatGridViewEditor.vue';

export default {
  name: 'GatGridVueMenu',
  props: ['orgColumns', 'currentView', 'viewGroupName', 'items', 'iconOnly'],
  components: { GatGridViewEditor },
  data() {
    return {
      showViewEditor: false,
      view: {},
    };
  },

  created() {},

  watch: {},

  computed: {
    views() {
      return this.$store.state.gatcomponents.gridViews.filter((item) => item.viewGroupName == this.viewGroupName);
    },
    viewBtnCaption() {
      if (this.currentView) {
        return this.currentView.name;
      }
      return 'view';
    },
  },

  methods: {
    addView() {
      this.view = {};
      this.showViewEditor = true;
    },
    editView() {
      this.view = this.duplicateViaJson(this.currentView);
      this.showViewEditor = true;
    },
    setView(view) {
      this.$emit('viewchosen', view);
    },
    viewEditorClosed(option) {
      if (option.action == 'deleted') {
        this.setView(null);
      }
      if (option.action == 'saved') {
        this.setView(option.view);
      }
      this.showViewEditor = false;
    },
  },
};
</script>

<style scoped></style>
