import { render, staticRenderFns } from "./testPage.vue?vue&type=template&id=48cd017a&scoped=true&"
import script from "./testPage.vue?vue&type=script&lang=js&"
export * from "./testPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48cd017a",
  null
  
)

export default component.exports