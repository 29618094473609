<template>
  <div>
    <h1 class="v-heading text-h3 text-sm-h3 mb-4">gat-group</h1>
    <p><code>gat-group</code> is a layout component used to group components together.</p>
    <p>
      In big forms the groups are normally collapsable. When collapsed showing a compact view of the child components
      value
    </p>
    <p>
      There are two slots to be used: <code>compact</code> for the collapsed layout and a <code>btn</code> slot for
      adding buttons to the top right og the group
    </p>

    <gat-code-editor
      value='<gat-group title="Customer / supplier details">

        <!-- compact slot -->
        <template slot="compact">
            <v-layout wrap>
                <gat-compact-field :value="client.NAME" label="Company name"  />
                <gat-compact-field :value="client.PHONE" label="Phone no" />
                <gat-compact-field :value="client.EMAIL" label="Email"  />
                <gat-compact-field :value="client.ACCOUNT_NO" label="Client no"/>
                <gat-compact-field :value="client.VAT_NO" label="Vat no"  />
                <gat-compact-field :value="client.EORI_NO" label="EORI no"/>
            </v-layout>
        </template>

        <!-- regular slot -->
        <v-layout wrap>
            <gat-edit v-model="client.NAME" label="Name" size="xs4"> </gat-edit>
            <gat-edit v-model="client.ACCOUNT_NO" label="Client no" size="xs2"> </gat-edit>
            <gat-edit v-model="client.PHONE" label="Phone" size="xs3"> </gat-edit>
            <gat-edit v-model="client.EMAIL" label="Email" size="xs3"> </gat-edit>
                <gat-edit v-model="client.VAT_NO" label="VAT no" size="xs4"> </gat-edit>
                <gat-edit v-model="client.EORI_NO" label="EORI no" size="xs4"> </gat-edit>
        </v-layout>

        <!-- button slot -->
        <v-btn slot="btn" outlined color="primary" small >
            <v-icon>mdi-plus</v-icon>Add
        </v-btn>

    </gat-group>'
      language="vue"
      :readonly="true" />

    <gat-form>
      <GatGroup
        class="pa-0"
        :title="propValues.title"
        :collapsed="propValues.collapsed"
        :readonly="propValues.readonly"
        :compact="propValues.compact"
        :collapsable="propValues.collapsable"
        :borders="propValues.borders">
        <template slot="compact">
          <v-layout wrap>
            <gat-compact-field :value="client.NAME" label="Company name" />
            <gat-compact-field :value="client.PHONE" label="Phone no" />
            <gat-compact-field :value="client.EMAIL" label="Email" />
            <gat-compact-field :value="client.ACCOUNT_NO" label="Client no" />
            <gat-compact-field :value="client.VAT_NO" label="Vat no" />
            <gat-compact-field :value="client.EORI_NO" label="EORI no" />
          </v-layout>
        </template>
        <v-layout wrap>
          <gat-edit v-model="client.NAME" label="Name" size="xs4"> </gat-edit>
          <gat-edit v-model="client.ACCOUNT_NO" label="Client no" size="xs2"> </gat-edit>
          <gat-edit v-model="client.PHONE" label="Phone" size="xs3"> </gat-edit>
          <gat-edit v-model="client.EMAIL" label="Email" size="xs3"> </gat-edit>
          <gat-edit v-model="client.VAT_NO" label="VAT no" size="xs4"> </gat-edit>
          <gat-edit v-model="client.EORI_NO" label="EORI no" size="xs4"> </gat-edit>
        </v-layout>

        <v-btn slot="btn" outlined color="primary" small> <v-icon>mdi-plus</v-icon>Add </v-btn>
      </GatGroup>
    </gat-form>

    <property-list v-model="propValues" :propList="properties" allowEdit="true" />
  </div>
</template>

<script>
export default {
  name: 'ViewGatGroup',
  props: [],
  components: {},
  data() {
    return {
      client: {
        NAME: 'GAT-Ship AS',
        PHONE: '+45 90 43 33 20',
        EMAIL: 'post@gatship.com',
        ACCOUNT_NO: '1021',
        VAT_NO: null,
        EORI_NO: null,
      },
      propValues: {
        disabled: false,
        readonly: false,
        collapsed: false,
        collapsable: true,
        title: 'Customer / supplier details',
      },
    };
  },

  created() {},

  watch: {},

  computed: {
    properties() {
      return [
        {
          name: 'compact',
          type: 'boolean',
          default: false,
          description: 'change the layout to non-collapsable compact mode.  Typically used in smaller modal forms',
        },
        {
          name: 'collapsed',
          type: 'boolean',
          default: false,
          description: 'shows a compact version of the group, using the compact-slot',
        },
        { name: 'collapsable', type: 'boolean', default: true, description: 'set to true to non-collapsable' },
        {
          name: 'readonly',
          type: 'boolean',
          default: false,
          description: 'Will set all child components readonly property',
        },
        { name: 'title', type: 'string', default: undefined, description: 'title/caption for the group' },
      ];
    },
  },

  methods: {},
};
</script>

<style scoped></style>
