<template>
  <gat-flex class="mb-1" :hide="hideValue" :size="size || 's'">
    <gat-field-spacer :size="size">
      <div class="compactLabel grey--text">{{ label }}</div>
      <slot>
        <v-icon v-if="checkbox" class="mr-1">{{ formatedValue() }}</v-icon>

        <a v-else-if="linkUrl" class="gridLink text-truncate mr-2" :href="linkUrl" :target="linkTarget">{{
          formatedValue()
        }}</a>
        <div v-else class="text-truncate text-body-2">
          <!--  <- Fjernet mr-2 -->
          <div v-if="!rows">
            <v-icon :color="iconColor" small v-if="prependIcon" class="mr-1">{{ prependIcon }}</v-icon>
            {{ formatedValue() }}
            <span>{{ appendText }}</span>
          </div>
          <template v-else>
            <div v-for="(n, index) in rowCount()" :key="index">
              {{ formatedValue(index + 1) }}
            </div>
          </template>
        </div>
      </slot>
    </gat-field-spacer>
  </gat-flex>
</template>

<script>
import numeral from 'numeral';
import GatFieldSpacer from './GatFieldSpacer.vue';
import GatFlex from './GatFlex.vue';

export default {
  name: 'GatCompactField',
  props: {
    value: [String, Number, Date, Boolean, Object, Array],
    label: String,
    size: String,
    isDate: Boolean,
    isCurrency: Boolean,
    isDateTime: Boolean,
    prependIcon: String,
    iconColor: String,
    linkUrl: String,
    linkTarget: String,
    checkbox: Boolean,
    hide: {
      type: [String, Boolean],
      default: undefined,
    },
    rows: Number,
    appendText: String,
    maxDecimals: Number,
  },
  components: { GatFlex, GatFieldSpacer },
  data() {
    return {};
  },

  created() {},

  watch: {},

  computed: {
    hideValue() {
      if (typeof this.hide === 'undefined' && !this.value) {
        return 'all';
      }
      return this.hide;
    },
  },

  methods: {
    rowCount() {
      if (this.rows) {
        return 0 + this.rows;
      }
      return 0;
    },

    getLineNo(text, no) {
      let result = '';
      const lines = text.split('\n');
      if (lines.length >= no) {
        result = lines[no - 1];
      }
      return result;
    },

    formatedValue(rowNo) {
      let result = this.value;
      if (this.isDateTime) {
        result = this.globalFormatDateTime(result);
      } else if (this.isDate) {
        result = this.globalFormatDate(result);
      }
      if (this.isCurrency) {
        if (result == null) {
          result = '';
        } else {
          result = numeral(result).format('0,0.00');
        }
      }
      if (this.maxDecimals) {
        if (result == null) {
          result = '';
        } else {
          result = numeral(result).format(`0.[${'00000000000000000'.substr(1, this.maxDecimals)}]`);
        }
      }
      if (this.checkbox) {
        if (this.value) {
          result = 'check';
        } else {
          result = 'close';
        }
      }
      if (rowNo > 0 && this.value) {
        result = this.getLineNo(this.value, rowNo);
      }
      return result;
    },
  },
};
</script>

<style scoped lang="scss">
.compactLabel {
  color: rgba(0, 0, 0, 0.75) !important;
  font-size: 75%;
}
.theme--dark {
  .compactLabel {
    color: rgba(255, 255, 255, 0.75) !important;
  }
}
</style>
