<template>
  <div class="propertyList">
    <v-divider />
    <div class="subtitle-1">EVENTS</div>
    <v-list dense>
      <v-list-item v-for="event in value" :key="event.name">
        <div class="layout api-item pa-2 wrap">
          <div class="flex xs6 md2">
            <div class="text-capitalize overline text--secondary text--darken-3">name</div>
            <span class="mono name">{{ event.name }}</span>
          </div>
          <div class="flex xs6 md1 text-xs-right">
            <div class="text-capitalize overline text--secondary text--darken-3">value</div>
            <span class="mono">{{ event.type }}</span>
          </div>

          <div class="flex xs12 md6">
            <div class="text-capitalize overline text--secondary text--darken-3">description</div>
            <div class="v-markdown">
              <p>{{ event.description }}</p>
            </div>
          </div>
        </div>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
export default {
  name: 'EventList',
  props: ['value'],
  components: {},
  data() {
    return {};
  },

  created() {},

  watch: {},

  methods: {},
};
</script>

<style scoped>
.api-item .mono.name {
  color: #d63200;
}

.mono {
  font-family: Roboto Mono, monospace;
  font-weight: 550;
}
</style>
