<template>
  <div class="pa-5">
    <h1 class="v-heading text-h3 text-sm-h3 mb-4">gat-grid events</h1>

    <v-layout wrap>
      <gat-grid
        size="xs6"
        title="gat-grid"
        :items="items"
        :columns="columns"
        :reloadBtn="true"
        :selectable="true"
        keyName="ID"
        :favorites="favorites"
        :rowMenu="rowMenu"
        @favClick="eventHandler($event, 'favClick')"
        @reload-clicked="eventHandler($event, 'reload-clicked')"
        @row-clicked="eventHandler($event, 'row-clicked')"
        @rowMenuClicked="eventHandler($event, 'rowMenuClicked')"
        @selectionChanged="eventHandler($event, 'selectionChanged')" />
      <gat-flex size="xs6" class="pl-4">
        Event <code>{{ lastEvent }}</code> <br />
        Parameter:
        <gat-code-editor :value="lastEventParam" language="js" />
      </gat-flex>
    </v-layout>

    <event-list :value="events" />
  </div>
</template>

<script>
export default {
  name: 'compname',
  props: [],
  components: {},
  data() {
    return {
      lastEvent: '',
      lastEventParam: '',
      favorites: [],
    };
  },

  created() {},

  watch: {},

  computed: {
    // rowMenuClicked
    events() {
      return [
        {
          name: 'favClick',
          type: 'object',
          description: 'triggered when the user clicks the favorite icon on a row.  rowItem as parameter',
        },
        {
          name: 'reload-clicked',
          type: null,
          description: 'triggered when the user clicks the reload btn in the grid',
        },
        { name: 'row-clicked', type: 'object', description: 'triggered a row is clicked.  rowItem as parameter' },
        {
          name: 'rowMenuClicked',
          type: 'object',
          description: 'triggered when a row menu item is clicked.  "row menu item" and "row item" as parameter',
        },
        {
          name: 'selectionChanged',
          type: 'array',
          description:
            'when selectable is used, and the selection is changed.  Gives an array with the keys of the selected rows.',
        },
      ];
    },
    columns() {
      const result = [
        { header: 'Name', field: 'NAME' },

        { header: 'Country', field: 'COUNTRY' },
        { header: 'EU country', field: 'EU', checkbox: true, hideOnPhone: false },
      ];
      return result;
    },
    items() {
      return [
        { ID: 10, NAME: 'GAT-Ship AS', EU: false, COUNTRY: 'Norway', LAST_CALL: '2020/2938' },
        { ID: 20, NAME: 'Wilmur Shipping', EU: true, COUNTRY: 'Denmark', LAST_CALL: '2020/1392' },
        { ID: 40, NAME: 'Shell inc', EU: false, COUNTRY: 'USA', LAST_CALL: '2019/0320' },
      ];
    },
    rowMenu() {
      return [
        { name: 'properties', caption: 'properties...', icon: 'mdi-playlist-edit', enabled: true },
        { name: 'delete', caption: 'delete...', icon: 'delete', enabled: true },
      ];
    },
  },

  methods: {
    eventHandler(param, eventName) {
      this.lastEvent = eventName;
      this.lastEventParam = JSON.stringify(param);

      if (eventName == 'favClick') {
        const idx = this.favorites.findIndex((x) => x.FAV_KEY == param.ID);
        if (idx >= 0) {
          this.favorites.splice(idx, 1);
        } else {
          this.favorites.push({ FAV_KEY: param.ID });
        }
      }
    },
  },
};
</script>

<style scoped></style>
