import Vue from 'vue';
import { LocalComponentMixin } from '@/components/CompMixin';
import App from './App.vue';
import store from './store';
import vuetify from './plugins/vuetify';
import router from './router';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import gsComponents from './gat-components/index';
import { enforceMaxChars } from './globalFlags';

// https://stackoverflow.com/questions/66120513/property-does-not-exist-on-type-window-typeof-globalthis
declare global {
  interface Window {
    messageBus?: any;
  }
}

gsComponents.install(Vue, { enforceMaxChars });
Vue.mixin(LocalComponentMixin);
Vue.config.productionTip = false;
window.messageBus = new Vue({});

new Vue({
  store,
  vuetify,
  router,
  render: (h) => h(App),
}).$mount('#app');
