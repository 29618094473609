/* eslint-disable no-param-reassign */
import GatButton from './gatButtons/GatButton.vue';
import GatButtonGroup from './gatButtons/GatButtonGroup.vue';
import GatDialog from './gatdialog/GatDialog.vue';
import GatGrid from './gatgrid/GatGrid.vue';
import GatEdit from './gatInput/GatEdit.vue';
import GatSelect from './gatInput/GatSelect.vue';
import GatMultiSelect from './gatInput/GatMultiSelect.vue';
import GatBottomPanel from './GatBottomPanel.vue';
import GatCompactField from './GatCompactField.vue';
import GatFieldSpacer from './GatFieldSpacer.vue';
import GatFlex from './GatFlex.vue';
import GatForm from './GatForm.vue';
import GatGroup from './GatGroup.vue';
import GatSubGroup from './GatSubGroup.vue';
import RecordPresenter from './RecordPresenter.vue';
import GatLookupEdit from './gatInput/GatLookupEdit.vue';
import GatTab from './GatTab.vue';
import GatReloadBtn from './GatReloadBtn.vue';
import GatCodeEdit from './gatInput/GatCodeEdit.vue';
import { GlobalComponentMixin } from './mixins/GlobalComponentMixin';
import { GlobalDateUtilsMixin } from './mixins/GlobalDateMixin';
import { GlobalMixin } from './mixins/GlobalMixin';
import GatCommon from './GatCommon';

export {
  GatButton,
  GatButtonGroup,
  GatDialog,
  GatGrid,
  GatEdit,
  GatSelect,
  GatMultiSelect,
  GatBottomPanel,
  GatCompactField,
  GatFieldSpacer,
  GatFlex,
  GatForm,
  GatGroup,
  GatSubGroup,
  RecordPresenter,
  GatLookupEdit,
  GatTab,
  GatReloadBtn,
  GatCodeEdit,
  GlobalComponentMixin,
  GlobalDateUtilsMixin,
  GlobalMixin,
  GatCommon,
};

function install(vue, options = {}) {
  vue.component('GatButton', GatButton);
  vue.component('GatButtonGroup', GatButtonGroup);
  vue.component('GatDialog', GatDialog);
  vue.component('GatGrid', GatGrid);
  vue.component('GatEdit', GatEdit);
  vue.component('GatSelect', GatSelect);
  vue.component('GatMultiSelect', GatMultiSelect);
  vue.component('GatBottomPanel', GatBottomPanel);
  vue.component('GatCompactField', GatCompactField);
  vue.component('GatFieldSpacer', GatFieldSpacer);
  vue.component('GatFlex', GatFlex);
  vue.component('GatForm', GatForm);
  vue.component('GatGroup', GatGroup);
  vue.component('GatSubGroup', GatSubGroup);
  vue.component('RecordPresenter', RecordPresenter);
  vue.component('GatLookupEdit', GatLookupEdit);
  if (!options?.excludeComponents?.gatTab) {
    vue.component('GatTab', GatTab);
  }
  vue.component('GatReloadBtn', GatReloadBtn);
  vue.mixin(GlobalDateUtilsMixin);
  vue.mixin(GlobalMixin);
  vue.mixin(GlobalComponentMixin);

  // Options assignment
  vue.prototype.$gatEnforceMaxChars =
    Object.hasOwn(options, 'enforceMaxChars') && typeof options.enforceMaxChars === 'boolean'
      ? options.enforceMaxChars
      : true;
}

export default { install };
