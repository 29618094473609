<template>
  <div>
    <prism-editor
      class="prsim-editor"
      v-model="code"
      :language="getLanguage"
      :lineNumbers="lineNumbers"
      :readonly="readonly"></prism-editor>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import prismjs from 'prismjs';
import PrismEditor from 'vue-prism-editor';
import 'prismjs/themes/prism.css';
// eslint-disable-next-line import/extensions
import 'prismjs/components/prism-sql.min.js';

export default {
  name: 'GatCodeEdit',
  props: {
    lineNumbers: Boolean,
    language: String,
    readonly: Boolean,
    value: String,
  },
  components: { PrismEditor },
  data() {
    return {
      code: null,
    };
  },

  created() {},

  computed: {
    getLanguage() {
      if (this.language) {
        return this.language;
      }
      return 'sql';
    },
  },

  watch: {
    value: {
      handler(val) {
        this.code = val;
      },
      immediate: true,
    },
    code(val) {
      if (val != this.value) {
        this.$emit('input', val);
      }
    },
  },

  methods: {},
};
</script>

<style>
.prism-editor-wrapper code {
  box-shadow: none !important;
}
</style>
