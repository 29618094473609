<template>
  <div class="pa-5">
    <h1 class="v-heading text-h3 text-sm-h3 mb-4">
      <a href="#inputs" class="text-decoration-none text-right text-md-left"></a> gat-edit
    </h1>
    <p>The <code>gat-edit</code> component is used for most input fields. By default the field is a text input.</p>
    <gat-form v-model="validForm" ref="form">
      <v-layout wrap>
        <v-btn v-if="false" small @click="setEditValue(123.456)">set value</v-btn>
        <gat-edit
          id="gat-edit-input"
          :size="propValues.size"
          :label="propValues.label"
          v-model="editValue"
          :dataType="propValues.dataType"
          :checkbox="propValues.checkbox"
          :dateEdit="propValues.dateEdit"
          :required="propValues.required"
          :timeEdit="propValues.timeEdit"
          :dateTimeEdit="propValues.dateTimeEdit"
          :rows="propValues.rows"
          :decimals="propValues.decimals"
          :label2="propValues.label2"
          :clearable="propValues.clearable"
          :suffix="propValues.suffix"
          :toggle="propValues.toggle"
          :disabled="propValues.disabled"
          :readonly="propValues.readonly"
          :hint="propValues.hint"
          :inlineEdit="propValues.inlineEdit"
          :noflex="propValues.noflex"
          :appendText="propValues.appendText"
          :uppercase="propValues.uppercase"
          :lowercase="propValues.lowercase"
          :checkedValue="propValues.checkedValue"
          :uncheckedValue="propValues.uncheckedValue"
          :validatorRules="propValues.validatorRules"
          :errorMessages="propValues.errorMessages"
          :markOptionalInputs="propValues.markOptionalInputs"
          :counter="propValues.counter"
          :showWeekDay="propValues.showWeekDay"
          :maxChars="propValues.maxChars"
          :mailto="propValues.mailto"
          :tel="propValues.tel"
          @input="eventTrigged.unshift({ name: 'input', param: $event })"
          @blur="eventTrigged.unshift({ name: 'blur', param: $event })"
          @onenterkey="eventTrigged.unshift({ name: 'onenterkey', param: $event })" />
        <span class="ml-4">
          <div class="text-capitalize overline text--secondary text--darken-3">value</div>
          <span id="edit-value">{{ editValue }}</span>
        </span>
        <span class="ml-4">
          <div class="text-capitalize overline text--secondary text--darken-3">datatype</div>
          {{ typeof editValue }}
        </span>

        <!-- <event-logger :value="eventTrigged"/> -->
      </v-layout>
    </gat-form>
    <event-list :value="events" />
    <property-list v-model="propValues" :propList="properties" allowEdit="true" />
  </div>
</template>

<script>
export default {
  name: 'ViewGatEdit',
  props: [],
  components: {},
  data() {
    return {
      eventTrigged: [],
      propValues: {
        clearable: false,
        decimals: 2,
        dataType: null,
        disabled: false,
        hint: 'hint here',
        label: 'gatEdit',
        label2: 'kl',
        noflex: false,
        size: 's',
        checkbox: false,
        dateEdit: true,
        required: false,
        timeEdit: false,
        dateTimeEdit: false,
        rows: null,
        showWeekDay: 'auto',
        suffix: undefined,
        toggle: false,
        readonly: false,
        uppercase: false,
        lowercase: false,
        checkedValue: true,
        uncheckedValue: false,
        maxChars: 25,
        validatorRules: [
          (val) => {
            if (val == 'tabu') {
              return 'a tabu';
            }
            return true;
          },
        ],
      },
      validForm: true,
      editValue: '2020-05-17',
    };
  },

  // 'uppercase','lowercase'

  computed: {
    events() {
      return [
        { name: 'blur', type: '', description: 'triggered when the user leaves the field (regular blur event)' },
        { name: 'input', type: 'Any', description: 'triggered when the value changes (regular Vue input event)' },
        {
          name: 'onenterkey',
          type: '',
          description: 'triggered when the user press and relases the enter key (only implemented for text fields)',
        },
      ];
    },
    properties() {
      return [
        {
          name: 'autofocus',
          type: 'boolean',
          default: false,
          description: 'set to true, to set the focus to this field automatically',
        },
        { name: 'checkbox', type: 'boolean', default: false, description: 'set to true for a checkbox' },
        {
          name: 'checkedValue',
          type: 'Any',
          default: true,
          description: 'value when a checkbox is checked.  Set :checkedValue=1 to match database true/false field',
        },
        {
          name: 'clearable',
          type: 'boolean',
          default: false,
          description: 'shows an X to clear the content of the field (only implemented on text fields)',
        },
        {
          name: 'counter',
          type: 'number',
          default: undefined,
          description:
            'Creates counter for input length.  Set to true to show counter only.  Set to number to specify max length (Does not apply any validation).(only implemented on text fields)',
        },
        { name: 'dataType', type: 'string', default: 'undefined', description: 'set to number to only allow numbers' },
        { name: 'dateEdit', type: 'boolean', default: false, description: 'set to true for a date edit input' },
        { name: 'dateTimeEdit', type: 'boolean', default: false, description: 'set to true for date and time input' },
        {
          name: 'decimals',
          type: 'number',
          default: 'undefined',
          description: 'number of decimals to display.  If null, the number of decimals will vary based on the value',
        },
        { name: 'disabled', type: 'boolean', default: false, description: 'disables the field' },
        {
          name: 'errorMessages',
          type: 'string',
          default: false,
          description:
            'Puts the input in an error state and passes through custom error messages. Will be combined with any validations that occur from the rules prop. This field will not trigger validation',
        },
        {
          name: 'hint',
          type: 'string',
          default: 'undefined',
          description: 'adds a hint to the input (text below the iput when the input has focus)',
        },
        { name: 'inlineEdit', type: 'boolean', default: false, description: 'Used for inputs in grids' },
        { name: 'timeEdit', type: 'boolean', default: false, description: 'set to true for a time edit input' },
        { name: 'label', type: 'string', default: 'undefined', description: 'field label / caption' },
        {
          name: 'label2',
          type: 'string',
          default: 'time',
          description: 'field label / caption for the time field when using dateTimeEdit',
        },
        { name: 'lowercase', type: 'boolean', default: 'false', description: 'makes text lowercase in a text field' },
        { name: 'mailto', type: 'boolean', default: false, description: 'will show a mailbutton that uses mailto' },
        {
          name: 'markOptionalInputs',
          type: 'boolean',
          default: false,
          description: 'set on all inputs if optional fields should be marked instead of required fields',
        },
        {
          name: 'maxChars',
          type: 'number',
          default: 'undefined',
          description: 'Limit the allowed number of characters in text fields. -1=varchar(max)',
        },
        {
          name: 'noflex',
          type: 'boolean',
          default: false,
          description: 'Used for inputs in grids (removes the gat-flex component)',
        },
        {
          name: 'readonly',
          type: 'boolean',
          default: false,
          description: 'set to true if user is not alloed to change the input',
        },
        { name: 'required', type: 'boolean', default: false, description: 'set to true if not allowed to be empty' },
        {
          name: 'rows',
          type: 'number',
          default: 'undefined',
          description: 'set to more than 1 to make a multi line text field',
        },
        {
          name: 'showWeekDay',
          type: 'string',
          default: 'auto',
          description:
            'show weekday as sufix on date fields. none, auto, dd, ddd, dddd (auto sets format depending on field width) ',
        },
        { name: 'size', type: 'string', default: 'm', description: 'xs s sm m l xl' },
        {
          name: 'suffix',
          type: 'string',
          default: 'undefined',
          description: 'adds text to the end of the input (inside the field)',
        },
        {
          name: 'tel',
          type: 'boolean',
          default: false,
          description: 'will show a button that allows call to the number in the field',
        },
        { name: 'toggle', type: 'boolean', default: 'false', description: 'uses a v-switch instead of a v-checkbox' },
        {
          name: 'uncheckedValue',
          type: 'Any',
          default: false,
          description: 'value when a checkbox is unchecked.  Set :uncheckedValue=0 to match database true/false field',
        },
        { name: 'uppercase', type: 'boolean', default: 'false', description: 'makes text uppercase in a text field' },
        {
          name: 'validatorRules',
          type: 'array, function',
          default: '[]',
          description:
            'Implemented for text and number fields only. Accepts a function or an array of functions. Functions pass an input value as an argument and must return either true / false or a string containing an error message. The input field will enter an error state if a function returns (or any value in the array contains) false or is a string',
        },
      ];
    },
  },

  created() {},

  watch: {},

  methods: {
    eventHandler(param, eventName) {
      this.lastEvent = { name: eventName, param };
      // this.lastEventTime = this.globalFormatDate(Date.now(),"HH:mm:ss")
      // this.lastEvent=eventName;
      // this.lastEventParam= JSON.stringify(param);

      if (eventName == 'blur') {
        this.$refs.form.validate();
      }
    },

    setEditValue(val) {
      this.editValue = val;
    },
    // validate(){
    //     this.$refs.form.validate();
    // }
  },
};
</script>

<style scoped></style>
