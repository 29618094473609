import { render, staticRenderFns } from "./GatDateEdit.vue?vue&type=template&id=5ede8164&scoped=true&"
import script from "./GatDateEdit.vue?vue&type=script&lang=js&"
export * from "./GatDateEdit.vue?vue&type=script&lang=js&"
import style0 from "./GatDateEdit.vue?vue&type=style&index=0&id=5ede8164&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ede8164",
  null
  
)

export default component.exports