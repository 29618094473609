<template>
  <div class="pa-5">
    <h1 class="v-heading text-h3 text-sm-h3 mb-4">gat-grid selection</h1>
    <gat-code-editor
      value='<gat-grid  :items="items" :columns="columns" keyName="ID" :selectedKeys="selectedItemKeys" :selectable=true @selectionChanged="selectedItemKeys=$event" />'
      language="vue" />

    <v-layout wrap>
      <gat-grid
        :items="items"
        :columns="columns"
        keyName="ID"
        :selectedKeys="selectedItemKeys"
        :selectable="true"
        @selectionChanged="selectedItemKeys = $event" />
    </v-layout>
    <br />

    <p>
      To add a selection column in the grid, set the <code>keyName</code> property, set the
      <code>selectable</code> property to true and add an event handler to the <code>selectionChanged</code> event
    </p>
    <p>
      The <code>selectedKeys</code> property can be used to set what rows are to be selected. Its usage is optional.
    </p>
    <p>The selectionChanged event will give an array with the key values of the selected rows</p>
    <gat-code-editor :value="JSON.stringify(selectedItemKeys)" language="js" />
  </div>
</template>

<script>
export default {
  name: 'ViewGatGridSelection',
  props: [],
  components: {},
  data() {
    return {
      selectedItemKeys: [],
    };
  },

  created() {},

  watch: {},

  computed: {
    columns() {
      const result = [
        { header: 'Name', field: 'NAME' },
        { header: 'Country', field: 'COUNTRY' },
        { header: 'EU country', field: 'EU', checkbox: true, hideOnPhone: false },
      ];
      return result;
    },
    items() {
      return [
        { ID: 10, NAME: 'GAT-Ship AS', EU: false, COUNTRY: 'Norway', LAST_CALL: '2020/2938' },
        { ID: 20, NAME: 'Wilmur Shipping', EU: true, COUNTRY: 'Denmark', LAST_CALL: '2020/1392' },
        { ID: 40, NAME: 'Shell inc', EU: false, COUNTRY: 'USA', LAST_CALL: '2019/0320' },
      ];
    },
  },

  methods: {},
};
</script>

<style scoped></style>
