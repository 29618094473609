import { v4 as uuid } from 'uuid';
import numeral from 'numeral';
import moment from 'moment';
import sanitizeHtml from 'sanitize-html';

export const GlobalMixin = {
  data: () => ({}),

  methods: {
    textToSafeHTML(htmlAsString) {
      // https://www.npmjs.com/package/sanitize-html
      const value = htmlAsString;
      const options = {
        disallowedTagsMode: 'escape',
        allowedTags: ['a', 'b', 'img', 'span'],
        transformTags: {
          a(tagName, attribs) {
            return {
              tagName,
              attribs: {
                ...attribs,
                class: 'gridLink',
              },
            };
          },
          span(tagName, attribs) {
            let classResult = '';
            if (attribs.class && attribs.class.includes(' ')) {
              const classes = attribs.class.split(' ');
              classes.forEach((item) => {
                if (item.includes('mdi')) {
                  classResult += ` ${item}`;
                }
                if (item.includes('text')) {
                  classResult += ` ${item}`;
                }
                if (item.includes('bg')) {
                  classResult += ` ${item}`;
                }
                if (item.includes('darken')) {
                  classResult += ` ${item}`;
                }
                if (item.includes('lighten')) {
                  classResult += ` ${item}`;
                }
              });
            }
            // My own custom magic goes here
            return {
              tagName: 'span',
              attribs: {
                class: classResult.trim(),
              },
            };
          },
        },
        allowedAttributes: {
          a: ['href', 'class'],
          img: ['src', 'width', 'height'],
          span: ['class'],
        },
        allowedSchemesByTag: {
          a: [],
          img: ['https'],
        },
        parseStyleAttributes: false,
      };
      const result = sanitizeHtml(value, options);
      return result;
    },
    duplicateViaJson(obj) {
      let result = {};
      if (obj) {
        const jsonStr = JSON.stringify(obj);
        result = JSON.parse(jsonStr);
      }
      return result;
    },

    GUID() {
      return uuid();
    },

    formatNumber(value, format) {
      let result = value;
      let formating = format;
      if (!format) {
        formating = '0,0.00';
      }
      if (value == null) {
        result = '';
      } else {
        result = numeral(value).format(formating);
      }
      return result;
    },

    getObjectChanges(Org, New) {
      const result = [];
      if (Org == null) {
        // eslint-disable-next-line no-param-reassign
        Org = {};
      }
      if (New == null) {
        // eslint-disable-next-line no-param-reassign
        New = {};
      }
      if (typeof Org == 'object' && typeof New == 'object') {
        // finner det nye og endrede properties

        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const prop in New) {
          const propOrg = Org[prop];
          const propNew = New[prop];
          if (typeof propOrg == 'object' && typeof propNew == 'object') {
            const propResult = this.getObjectChanges(propOrg, propNew);
            if (propResult.length > 0) {
              // eslint-disable-next-line array-callback-return
              propResult.map((item) => {
                result.push(item);
              });
            }
          } else {
            const value1 = JSON.stringify(propOrg);
            const value2 = JSON.stringify(propNew);
            if (value1 != value2) {
              result.push({ propertyName: prop, oldValue: value1, newValue: value2 });
            }
          }
        }
        // finner slettede properties
        // eslint-disable-next-line no-restricted-syntax
        for (const orgProp in Org) {
          if (!Object.prototype.hasOwnProperty.call(New, orgProp)) {
            result.push({ propertyName: orgProp, oldValue: JSON.stringify(Org[orgProp]), newValue: null });
          }
        }
      }
      return result;
    },

    globalObjectsAreAlike(objA, objB) {
      let result = false;
      const v1 = JSON.stringify(objA);
      const v2 = JSON.stringify(objB);
      result = v1 == v2;

      return result;
    },

    setHistoryField(obj, fieldName = 'HISTORY') {
      let currentValue = null;
      if (Object.prototype.hasOwnProperty.call(obj, fieldName)) {
        currentValue = obj[fieldName];
      }
      if (!currentValue) {
        const newValue = moment().format('YYMMDD-HHmm-') + this.$store.state.user.userName.substr(0, 12);
        // eslint-disable-next-line no-param-reassign
        obj[fieldName] = newValue;
      } else {
        // yymmdd-uuuuuuuuuuuu--999--yymmdd-hhmm-uuuuuuuuuuuu
        // 191212-SUPER--001--191212-1447-SUPER
        // Added date + addet time + added by user + number of modifications (max length = 3) +modified date + modified time + modified by user (user name length,max 12 chars)
        let sepIndex = currentValue.indexOf('--');
        if (sepIndex == -1) {
          sepIndex = currentValue.length;
        }
        let newValue = currentValue.substr(0, sepIndex);
        const changeCount = currentValue.substr(sepIndex + 2, 3);
        let newCount = (parseInt(changeCount, 10) || 0) + 1;
        newCount = newCount.toString().padStart(3, '0');
        newValue = `${newValue}--${newCount}--${moment().format(
          'YYMMDD-HHmm-'
        )}${this.$store.state.user.userName.substr(0, 12)}`;
        // eslint-disable-next-line no-param-reassign
        obj[fieldName] = newValue;
      }
    },

    splitSize(fields, maxHorizontal) {
      let size = 's';
      let fieldsUsed = 0;
      // eslint-disable-next-line array-callback-return
      fields.map((field) => {
        if (field) {
          fieldsUsed += 1;
        }
      });
      if (fieldsUsed > maxHorizontal) {
        const rows = Math.ceil(fieldsUsed / maxHorizontal);
        fieldsUsed /= rows;
      }
      if (fieldsUsed > 0) {
        const colsPerField = 12 / fieldsUsed;
        size = `xs${colsPerField}`;
      }

      return size;
    },

    subscribe(eventName, func) {
      // eslint-disable-next-line no-restricted-globals
      this.messageBus.$on(event, func);
    },
  },
};
