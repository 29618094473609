import Vue from 'vue';
import Vuex from 'vuex';
import vuex_gatcomponents from '@/gat-components/store/gatComponents';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    gatcomponents: vuex_gatcomponents,
  },
});
