<template>
  <div>
    <gat-form ref="gatform" :readonly="isChecked">
      <gat-group title="Gruppe A" :collapsed="true">
        <gat-edit label="Navn" v-model="navn" clearable required :maxChars="20" />
        <gat-lookup-edit
          :label="'Harbour'"
          v-model="tall3"
          :searchresult="items"
          @getItems="getItems($event)"
          textFieldName="NAME"
          codeFieldName="ID"
          :cashSearches="true"
          clearable
          :required="true"></gat-lookup-edit>
        <!-- <gat-lookup-edit label="Ny comp" :items="items" v-model="editValue" codeFieldName = "ID" textFieldName = "NAME" :required="true" size="m" /> -->
        <!-- <gat-edit label="Navn"  required dateEdit /> -->
      </gat-group>
      <gat-group title="Gruppe B" :collapsed="true">
        <div class="test">
          <div class="ok">
            <div class="ok2">
              <div class="ok3">
                <gat-edit label="Tall" v-model="tall" dataType="number" clearable required />
                <gat-edit label="Tall2" v-model="tall2" dataType="number" clearable required />
              </div>
            </div>
          </div>
        </div>
      </gat-group>
      <gat-group title="Gruppe C" :collapsed="true"> </gat-group>
      <!-- <gat-edit label="Checkbox" v-model="isChecked" checkbox  @input="eventTrigged.unshift({'name':'input','param':$event})" /> -->
      <v-btn text @click="$refs.gatform.validate()" color="primary">validate</v-btn>
      <v-btn text @click="$refs.gatform.resetValidation()" color="primary">clear validation</v-btn>
      <v-btn text @click="tall2 = null" color="primary">clear input</v-btn>

      <!--    <v-btn text @click="datoTid='2021-05-17'" color ="primary">set</v-btn>
            <v-btn text @click="datoTid='2021-05-17T23:15:00'" color ="primary">set t</v-btn>
            <v-btn text @click="datoTid=new Date()" color ="primary">set d</v-btn> -->
      <!-- <v-btn text @click="tall=310910.231" color ="primary">set</v-btn> -->

      <!-- <gat-edit label="Fra" v-model="datoTid" :dateEdit="true" size="s" :readonly="isReadOnly" />
            <gat-edit label="Fra" v-model="datoTid" :dateTimeEdit="true" size="sm" :readonly="isReadOnly" />
            <gat-edit label="Fra" v-model="datoTid" :TimeEdit="true" :readonly="isReadOnly"   /> -->
      <!-- <gat-edit label="Til" v-model="datoTid" :dateEdit="true" :validatorRules="validateDate" /> -->
      <!-- <gat-select size="sm" label="Number format" :items="numberFormats" :value="numberFormat" @input="setNumberFormat"  />             -->

      <!-- <event-logger :value="eventTrigged" /> -->
      <!-- v:{{tall}} -->
    </gat-form>
  </div>
</template>

<script>
// import EventLogger from '../../components/EventLogger.vue';
import numeral from 'numeral';
import 'numeral/locales/de';
import 'numeral/locales/nl-nl';
import GatLookupEdit from '../../gat-components/gatInput/GatLookupEdit.vue';

export default {
  name: 'TestPage',
  props: [],
  components: { GatLookupEdit },
  data() {
    return {
      datoTid: null,
      tall: 12056.219,
      tall2: 123,
      tall3: 'Berthed',
      navn: 'Mia',
      eventTrigged: [],
      items: [
        { ID: 0, NAME: 'Expected' },
        { ID: 1, NAME: 'Berthed' },
        { ID: 2, NAME: 'Anchored' },
        { ID: 3, NAME: 'Sailed' },
        { ID: 4, NAME: 'Archived' },
        { ID: 5, NAME: 'Cancelled' },
      ],
      editValue: [2],

      someValue: {
        dateTime: '2020-10-30T12:19:00',
        number: undefined,
      },
      someOtherValue: {
        dateTime: '2020-12-24T06:00:00',
        number: 500,
      },
      clearedValue: {
        dateTime: null,
        number: null,
      },
      isChecked: null,
      isReadOnly: null,
      numberFormat: 'de',
      numberFormats: [
        { value: 'en', text: '10,000.00' },
        { value: 'de', text: '10 000,00' },
        { value: 'nl-nl', text: '10.000,00' },
      ],
    };
  },

  created() {
    numeral.locale(this.numberFormat);
  },

  watch: {},

  methods: {
    getItems() {
      this.items = [
        { ID: 0, NAME: 'Expected' },
        { ID: 1, NAME: 'Berthed' },
        { ID: 2, NAME: 'Anchored' },
      ];
    },
    setNumberFormat(n) {
      numeral.locale(n);
      console.log(n);
    },
    setOtherValues() {
      this.someValue.number = this.someOtherValue.number;
      this.someValue.dateTime = this.someOtherValue.dateTime;
    },

    clearValues() {
      this.someValue.number = undefined;
      this.someValue.dateTime = undefined;
    },

    validateDateTime(dateTime) {
      console.log('datoTid: ', dateTime);
      if (dateTime.includes('12')) {
        return 'ikke 12...';
      }
      return true;
    },

    validateDate(dato) {
      console.log('dato: ', dato);
      return 'ups!';
    },

    validator(text) {
      const result = true;
      if (text === 'no') {
        return 'no can do';
      }
      return result;
    },
  },
};
</script>

<style scoped></style>
