<template>
  <div class="pa-5">
    <h1 class="v-heading text-h3 text-sm-h3 mb-4">gat-multi-select</h1>
    <p>
      The <code>gat-multi-select</code> component is used for input-select/dropdown where multiple values can be
      selected
    </p>
    <p>
      The components needs a data source assigned to the items property.<br />
      Set textFieldName to control what data is displayed in the drop down, and set codeFieldName to control what data
      is used as value.
    </p>

    <gat-code-editor
      value='<gat-multi-select v-model="selectedCallStatuses" :items="callStatuses" label="Status filter" textFieldName="NAME" codeFieldName="ID" > </gat-multi-select>'
      language="vue"
      :readonly="true" />
    <gat-code-editor
      value="callStatuses:[ {ID:0,NAME:'Expected'}, {ID:1,NAME:'Berthed'}, {ID:2,NAME:'Anchored'}, {ID:3,NAME:'Sailed'}, {ID:4,NAME:'Archived'} {ID:5,NAME:'Cancelled'}]"
      language="js"
      :readonly="true" />
    <gat-form v-model="validForm" ref="form">
      <v-layout wrap>
        <gat-multi-select
          :chips="propValues.chips"
          :combobox="propValues.combobox"
          :codeFieldName="propValues.codeFieldName"
          :clearable="propValues.clearable"
          :disabled="propValues.disabled"
          :items="propValues.items"
          :label="propValues.label"
          :inlineEdit="propValues.inlineEdit"
          :noflex="propValues.noflex"
          :maxVisibleItemCount="propValues.maxVisibleItemCount"
          :prependIcon="propValues.prependIcon"
          :readonly="propValues.readonly"
          :required="propValues.required"
          :selectAllOption="propValues.selectAllOption"
          :size="propValues.size"
          :textFieldName="propValues.textFieldName"
          :useIcons="propValues.useIcons"
          v-model="editValue"
          @blur="$refs.form.validate()"
          @items-selected="selectedValue = $event">
        </gat-multi-select>

        <span class="ml-4">
          <div class="text-capitalize overline text--secondary text--darken-3">value</div>
          {{ editValue }}
        </span>
      </v-layout>
    </gat-form>

    <!-- <event-list  :value="events" /> -->
    <property-list v-model="propValues" :propList="properties" allowEdit="true" />
  </div>
</template>

<script>
export default {
  name: 'ViewGatSelect',
  props: [],
  components: {},
  data() {
    return {
      selectedValue: null,
      selectedItems: null,
      propValues: {
        chips: true,
        useIcons: false,
        readonly: false,
        disabled: false,
        returnObject: false,
        selectAllOption: true,
        label: 'Port call status',
        maxVisibleItemCount: 3,
        codeFieldName: 'ID',
        textFieldName: 'NAME',
        items: [
          { ID: 0, NAME: 'Expected' },
          { ID: 1, NAME: 'Berthed' },
          { ID: 2, NAME: 'Anchored' },
          { ID: 3, NAME: 'Sailed' },
          { ID: 4, NAME: 'Archived' },
          { ID: 5, NAME: 'Cancelled' },
        ],
      },
      validForm: true,
      editValue: [0, 1, 2],
    };
  },

  created() {},

  watch: {},

  computed: {
    properties() {
      return [
        {
          name: 'autofocus',
          type: 'boolean',
          default: false,
          description: 'set to true, to set the focus to this field automatically',
        },
        { name: 'chips', type: 'boolean', default: true, description: 'will show selected values as chips' },
        {
          name: 'codeFieldName',
          type: 'string',
          default: 'text',
          description: 'field/property name in data source (items) used as value for the drop down',
        },
        { name: 'disabled', type: 'boolean', default: false, description: 'disables the field' },
        {
          name: 'clearable',
          type: 'boolean',
          default: false,
          description: 'shows an X to clear the content of the field (only implemented on text fields)',
        },
        { name: 'items', type: 'array', default: [], description: 'the datasource - an array of objects' },
        { name: 'label', type: 'string', default: 'undefined', description: 'field label / caption' },
        {
          name: 'maxVisibleItemCount',
          type: 'number',
          default: 'undefined',
          description: 'number of items that can be rendered in the input, before (+ x more) appears',
        },
        { name: 'prependIcon', type: 'string', default: false, description: 'Adds an icon before the input field' },
        {
          name: 'readonly',
          type: 'boolean',
          default: false,
          description: 'set to true if user is not alloed to change the input',
        },
        { name: 'required', type: 'boolean', default: false, description: 'set to true if not allowed to be empty' },
        { name: 'size', type: 'string', default: 'm', description: 'xs s sm m l xl' },
        {
          name: 'selectAllOption',
          type: 'boolean',
          default: true,
          description: 'set to false to remove the select all checbox item',
        },
        {
          name: 'textFieldName',
          type: 'string',
          default: 'value',
          description: 'field/property name in data source (items) displayed to the user',
        },
      ];
    },
  },

  methods: {
    setValues() {
      this.editValue = [0, 1, 2, 3, 4, 5];
    },
  },
};
</script>

<style scoped></style>
